<template>
    <div class="cfa-root">
        <c-f-a-nav>
            <template v-slot:title>
                <div v-show="!waitingAPIResponse"
                    class="w-100 pt-5 text-start mb-3 d-flex justify-content-between align-items-center efwegl">
                    <!-- <h1 class="cfa-section-title"><span class="cfa-primary">CAMPAGNE : </span>{{ groupDetails.detail.name }}</h1> -->
                    <h1 class="cfa-section-title">
                        {{ $t('campaign') }} : {{ campaignName }}
                    </h1>
                    <!-- <div v-if="status == 'created'">
                        <span class="badge rounded-pill badge-danger- cfa-btn-gold p-2"
                            style="font-size: .9em;">{{ $t('waiting_validation') }}&nbsp;</span>
                    </div>
                    <div v-if="status == 'In progress'">
                        <span class="badge rounded-pill badge-danger- cfa-btn-gold p-2"
                            style="font-size: .9em;">{{$t('compaign_court')}}&nbsp;</span>
                    </div> -->
                    <div v-html="formatCampaignStatus">

                    </div>
                </div>
            </template>
            <template v-slot:content>
                <div v-show="!waitingAPIResponse" class="cfa-page">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="d-flex justify-content-between align-items-center mt-2">
                            <!--<span class="badge rounded-pill badge-danger- cfa-btn-secondary p-3">CAMPAGNE EN COUR&nbsp;</span>-->
                        </div>
                        <div class="row mb-1 d-flex flex-wrap">
                            <div class="col-12 col-md-3">
                                <div class="bg-white d-flex py-3 gBlock">
                                    <div class="p-3 gFolder-container">
                                        <img width="30" src="@/assets/img/png/phishing.png"
                                            alt="dashboard state image" />
                                    </div>
                                    <div>
                                        <p>{{ orgName }}</p>
                                        <p>{{$t('phishing_compaign')}}</p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 col-md-3">
                                <div class="bg-white gBlock d-flex py-3">
                                    <div class="p-3 gFolder-container">
                                        <img width="30" src="@/assets/img/png/groupe.png" alt="dashboard state image" />
                                    </div>
                                    <div>
                                        <p>{{ groupLength }} {{$t('user')}}</p>
                                        <p>{{$t('in_the_group')}}</p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 col-md-3">
                                <div class="bg-white gBlock d-flex py-3">
                                    <div class="p-3 gFolder-container">
                                        <img width="30" src="@/assets/img/png/start.png" alt="dashboard state image" />
                                    </div>
                                    <div>
                                        <!--<p>03 Juin 2013, 08:00</p>-->
                                        <!-- <p>{{ date == "" ? "---" : date }}</p>
                                        <p>à {{ hour }}</p> -->
                                        <p>{{ groupDetailsData ? formatDateToLocal(groupDetailsData.launch_date, "fr-FR") : "" }}</p>
                                        <p>{{$t('release_date')}}</p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 col-md-3">
                                <div class="bg-white gBlock d-flex py-3">
                                    <div class="p-3 gFolder-container">
                                        <img width="30" src="@/assets/img/png/time-left.png"
                                            alt="dashboard state image" />
                                    </div>
                                    <div>
                                        <!--<p>17 Juin 2013, 19:00</p>-->
                                        <!-- <p>{{ dateEnd == "" ? "---" : dateEnd }}</p>
                                        <p>à {{ hourEnd }}</p> -->
                                        <p>{{ groupDetailsData ? formatDateToLocal(groupDetailsData.send_by_date, "fr-FR") : "" }}</p>
                                        <p>{{$t('end-date')}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-2">
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="row">
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <div id="timeline_chart"></div>
                                    </div>
                                </div>
                                <div class="compagne">
                                    <div class="cfa-welcome">
                                        <h6>{{$t('email_sent')}}</h6>
                                        <DoughnutChart1 :valueCountUp="true" :valueCountUpDuration="700"
                                            :customPercentSize="totalStats" :classValue="'chartTextStyle2'" :width="80"
                                            :height="80" time="yes" :percent="(this.emailsEnvoyes) || '0'" :visibleValue="true"
                                            foregroundColor="rgb(3, 57, 60)" backgroundColor="#57b99c"
                                            :strokeWidth="15" />
                                    </div>
                                    <div class="cfa-welcome">
                                        <h6>Email ouverts</h6>
                                        <DoughnutChart1 :valueCountUp="true" :valueCountUpDuration="700"
                                            :customPercentSize="100" :classValue="'chartTextStyle2'" :width="80"
                                            :height="80" time="yes" :percent="this.emailsOuverts || '0'" :visibleValue="true"
                                            foregroundColor="rgb(3, 57, 60)" backgroundColor="#f0c056"
                                            :strokeWidth="15" />
                                    </div>
                                    <div class="cfa-welcome">
                                        <h6>Liens clickés</h6>
                                        <DoughnutChart1 :valueCountUp="true" :valueCountUpDuration="700"
                                            :customPercentSize="100" :classValue="'chartTextStyle2'" :width="80"
                                            :height="80" time="yes" :percent="this.liensCliques || '0'" :visibleValue="true"
                                            foregroundColor="rgb(3, 57, 60)" backgroundColor="#f0c056"
                                            :strokeWidth="15" />
                                    </div>
                                    <div class="cfa-welcome">
                                        <h6>Données soumises</h6>
                                        <DoughnutChart1 :valueCountUp="true" :valueCountUpDuration="700"
                                            :customPercentSize="100" :classValue="'chartTextStyle2'" :width="80"
                                            :height="80" time="yes" :percent="this.donnesSoumises || '0'"
                                            :visibleValue="true" foregroundColor="rgb(3, 57, 60)"
                                            backgroundColor="#df6556" :strokeWidth="15" />
                                    </div>
                                    <div class="cfa-welcome">
                                        <h6>Email signalé</h6>
                                        <DoughnutChart1 :valueCountUp="true" :valueCountUpDuration="700"
                                            :customPercentSize="100" :classValue="'chartTextStyle2'" :width="80"
                                            :height="80" time="yes" :percent="this.emailsSignales || '0'"
                                            :visibleValue="true" foregroundColor="rgb(3, 57, 60)"
                                            backgroundColor="#df6556" :strokeWidth="15" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex justify-content-end align-items-center my-3">
                            <!--<h6 class="mb-0 p-1">Dashbaord</h6>-->
                            <button class="btn cfa-btn-primary" @click="downloadReport" >
                                <i class="fa fa-download"></i>
                                GENERER LE RAPPORT
                            </button>
                        </div>

                        <h5 class="mb-1 p-1 mt-4">Details</h5>
                        <div class="row">
                            <Table class="px-0" max-th-width="0" minHeight="15vh" :hideControl="true"
                                last-cell-width="0" :columns="fieldsColumns" :data="fields"
                                :columns-action="fieldsToListen" classFilter="" @action="getCallToAction"></Table>
                        </div>

                        <h5 class="mb-1 p-1 mt-4">Utilisateurs</h5>
                        <div class="row">
                            <div class="cfa-welcome">
                                <!--<table-expend class="px-0" max-th-width="0" last-cell-width="0" :allow-extension="false"
                                    :columns="resultColumns" :data="result" :columns-action="resultToListen"
                                    classFilter="" @action="getCallToAction"></table-expend>-->
                                <Table class="px-0" max-th-width="0" minHeight="15vh" 
                                last-cell-width="0" :columns="resultColumns" :data="result"
                                :columns-action="resultToListen" classFilter="" @action="getCallToAction"></Table>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- edit campaign -->
                <scalable-modal @callback="confirmUpdate" :break="exitEditModal" label="EDITER LA CAMPAGNE"
                    @close="openUpdateModal = false" :open="openUpdateModal" :advancedControl="true"
                    validationButtonText="Enregistrer" exitButtonText="Annuler" display-mode="flex"
                    :style="'width: 40em; height: auto; margin-top: 2em; margin-bottom: 2em; background-color: #f0f0f0;'">
                    <template v-slot:content>
                        <!-- blo1 -->
                        <div v-if="tempUpdateID == null" class="w-100">

                            <div class="row w-100 m-inherit" style="margin-top: 2vh; margin-bottom: 2vh;">
                                <div class="form-group py-2 ps-0 pe-0 pe-md-2 col-6">
                                    <p class="mb-2"><strong>NOM</strong></p>
                                    <div class="d-flex flex-row">
                                        <input class="form-control" type="text" v-model="updateDataPayload.name">
                                    </div>
                                </div>
                                <div class="form-group py-2 ps-0 pe-0 pe-md-2 col-6">
                                    <p class="mb-2"><strong>GROUPE</strong></p>
                                    <!--
                    <div class="d-flex flex-row">
                        <input class="form-control" type="text" v-model="updateDataPayload.groups[0].name">
                    </div>
                    -->
                                    <div class="d-flex flex-row">
                                        <select class="form-control" v-model="newGroup">
                                            <option selected disabled value="">{{ updateDataPayload.groups[0].name }}
                                            </option>
                                            <option v-for="group in filteredGroups" :key="group.id" :value="group.id">{{
                                                group.name }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div class="row w-100 m-inherit" style="margin-top: 2vh;">
                                <div class="form-group py-2 ps-0 pe-0 pe-md-2 col-6">
                                    <p class="mb-2"><strong>TEMPLATE</strong></p>
                                    <div class="d-flex flex-row">
                                        <select class="form-control" v-model="newTemp">
                                            <option disabled selected value="">{{ updateDataPayload.template.name }}
                                            </option>
                                            <option v-for="template in filteredTemplate" :key="template.id"
                                                :value="template.id">{{ template.name }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-group py-2 col-6 px-0">
                                    <p class="mb-2"><strong>PAGE</strong></p>
                                    <div class="d-flex flex-row">
                                        <select class="form-control" v-model="newPage">
                                            <option selected disabled value="">{{ updateDataPayload.page.name }}
                                            </option>
                                            <option v-for="page in filteredLandings" :key="page.id" :value="page.id">{{
                                                page.name }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div class="row w-100 m-inherit" style="margin-top: 2vh; margin-bottom: 2vh;">
                                <div class="form-group py-2 ps-0 pe-0 pe-md-2 col-6">
                                    <p class="mb-2"><strong>DATE DE LANCEMENT</strong></p>
                                    <div class="d-flex flex-row">
                                        <VueDatePicker
                                            :placeholder="updateDataPayload.launch_date == null ? 'NON DEFINI' : formatDate(updateDataPayload.launch_date)"
                                            v-model="updateDataPayload.launch_date" locale="fr" cancelText="Annuler"
                                            selectText="Choisir" :title="'DEFINIR UNE DATE'"></VueDatePicker>
                                    </div>
                                </div>
                                <div class="form-group py-2 col-6 px-0">
                                    <p class="mb-2"><strong>DATE DE FIN</strong></p>
                                    <div class="d-flex flex-row">
                                        <VueDatePicker
                                            :placeholder="updateDataPayload.send_by_date == null ? 'NON DEFINI' : formatDate(updateDataPayload.send_by_date)"
                                            v-model="updateDataPayload.send_by_date" locale="fr" cancelText="Annuler"
                                            selectText="Choisir" :title="'DEFINIR UNE DATE'"></VueDatePicker>
                                    </div>
                                </div>
                            </div>

                            <!-- <div class="row w-100 m-inherit" style="margin-top: 2vh;">
                    <div class="form-group py-2 ps-0 pe-0 pe-md-2 col-6">
                    <p class="mb-2"><strong>URL<e style="color: red">*</e></strong></p>
                    <div class="d-flex flex-row">
                        <input class="form-control" type="text" v-model="updateDataPayload.url">
                    </div>
                    </div>

                    <div class="form-group py-2 col-6 px-0">
                    <p class="mb-2"><strong>SMTP<e style="color: red">*</e></strong></p>
                    <div class="d-flex flex-row">
                        <input class="form-control" type="text" v-model="updateDataPayload.smtp">
                    </div>
                    </div>
                </div> -->

                        </div>
                        <div v-else>
                            <div class="cfa-page">
                                <div class="d-flex justify-content-center align-items-center oups" style="min-height: 40vh">
                                    <div class="preloader_center d-flex flex-column">
                                        <img src="../../assets/brand/cfa-favicon1.png" alt="cfa preloader">
                                        <p class="pl-3 fw-bold">chargement . . .</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </scalable-modal>

                <div v-show="waitingAPIResponse" class="cfa-page">
                    <div class="d-flex justify-content-center align-items-center oups" style="min-height: 70vh">
                        <div class="preloader_center d-flex flex-column">
                            <img src="../../assets/brand/cfa-favicon1.png" alt="cfa preloader">
                            <p class="pl-3 fw-bold">chargement . . .</p>
                        </div>
                    </div>
                </div>

                <!-- Show user detail -->
                <scalable-modal @callback="confirmUpdate" :break="exitUserShowModal" label="DETAIL UTILISATEUR" :disableControl="true"
                uid="cfa-modal-org-update" @close="openShowDetails = false" :open="openShowDetails" :advancedControl="false"
                validationButtonText="Enregistrer" exitButtonText="Annuler" display-mode="flex"
                :style="'width: 40em; height: auto; margin-top: 2em; margin-bottom: 2em; '" class="user-activity-modal" >
                <template v-slot:content>
                    <!-- blo1 -->
                    <div class="w-full header ">
                        <p>Activite de la campagne</p>
                    </div>
                    <div class="w-100 event-content">
                        <div class="row w-100">
                            <div class="email-part py-4 my-1">
                                <span class="mb-2"><strong>Nom</strong></span>
                                <span>{{ currentUserDetail.first_name }} {{ currentUserDetail.last_name }}</span>
                            </div>
                        </div>
                        <div class="row w-100">
                            <div class="email-part py-4 my-1">
                                <span class="mb-2"><strong>Email</strong></span>
                                <span>{{ currentUserDetail.email }}</span>
                            </div>
                        </div>
                        <div class="row w-100">
                            <div class="form-group event-part">
                                <div class="event-label">
                                    <div class="event-icon" :class="{'event-ok-icon': summary.launch_date}">
                                        <i class="fa fa-timeline"></i>
                                    </div>
                                    <p>Campagne Cree le</p>
                                </div>
                                <p> {{ formatDateToLocal(summary.launch_date, "fr-FR") }} </p>
                            </div>
                        </div>
                        <div class="row w-100">
                            <div class="form-group event-part">
                                <div class="event-label">
                                    <div class="event-icon" :class="{'event-ok-icon': currentUserDetailActions && currentUserDetailActions.email_sent[0]}" >
                                        <i class="fa fa-envelope"></i>
                                    </div>
                                    <p>Email envoye <span v-if="currentUserDetailActions && currentUserDetailActions.email_sent[0]"> ({{ currentUserDetailActions && currentUserDetailActions.email_sent[1] }})</span> </p>
                                </div>
                                <p :class="{ 'event-ok': currentUserDetailActions && currentUserDetailActions.email_sent[0] }"> {{ currentUserDetailActions && currentUserDetailActions.email_sent[0] ? "Oui" : "Non" }} </p>
                            </div>
                        </div>
                        <div class="row w-100">
                            <div class="form-group event-part">
                                <div class="event-label">
                                    <div class="event-icon" :class="{'event-ok-icon': currentUserDetailActions && currentUserDetailActions.email_opened[0]}">
                                        <i class="fa fa-envelope-open"></i>
                                    </div>
                                    <p>Email Ouvert <span v-if="currentUserDetailActions && currentUserDetailActions.email_opened[0]"> ({{ currentUserDetailActions && currentUserDetailActions.email_opened[1] }})</span></p>
                                </div>
                                <p :class="{ 'event-ok': currentUserDetailActions && currentUserDetailActions.email_opened[0] }"> {{ currentUserDetailActions && currentUserDetailActions.email_opened[0] ? "Oui" : "Non" }} </p>
                            </div>
                        </div>
                        <div class="row w-100">
                            <div class="form-group event-part">
                                <div class="event-label">
                                    <div class="event-icon" :class="{'event-ok-icon': currentUserDetailActions && currentUserDetailActions.clicked_link[0]}">
                                        <i class="fa fa-link"></i>
                                    </div>
                                    <p>Lien Clicke <span v-if="currentUserDetailActions && currentUserDetailActions.clicked_link[0]"> ({{ currentUserDetailActions && currentUserDetailActions.clicked_link[1] }})</span></p>
                                </div>
                                <p :class="{ 'event-ok': currentUserDetailActions && currentUserDetailActions.clicked_link[0] }"> {{ currentUserDetailActions && currentUserDetailActions.clicked_link[0] ? "Oui" : "Non" }} </p>
                            </div>
                        </div>
                        <div class="row w-100">
                            <div class="form-group event-part">
                                <div class="event-label">
                                    <div class="event-icon" :class="{'event-ok-icon': currentUserDetail.submitted_data}">
                                        <i class="fa fa-paper-plane"></i>
                                    </div>
                                    <p>Donnees Soumis</p>
                                </div>

                                <p :class="{ 'event-ok': currentUserDetail.submitted_data }"> {{ currentUserDetail.submitted_data ? "Oui" : "Non" }} </p>
                            </div>
                        </div>
                        

                    </div>
                    <div v-if="waitingAPIResponse">
                    <div class="cfa-page">
                        <div class="d-flex justify-content-center align-items-center oups" style="min-height: 40vh">
                            <div class="preloader_center d-flex flex-column">
                                <img src="../../assets/brand/cfa-favicon1.png" alt="cfa preloader">
                                <p class="pl-3 fw-bold">{{ $t('loading') }}</p>
                            </div>
                        </div>
                    </div>
                    </div>
                </template>
                </scalable-modal>

            </template>
        </c-f-a-nav>
        <c-f-a-footer></c-f-a-footer>
    </div>
</template>

<script>
import CFAFooter from '@/components/nav/CFAFooter.vue'
import CFANav from '@/components/nav/CFANav.vue'
import DoughnutChart1 from '@/components/shared/chart/DoughnutChart1.vue'
//import TableExpend from "@/components/shared/table/TableExpend.vue";
import Table from "@/components/shared/table/Table.vue";
import { getAPI } from "@/axios-api";
import ScalableModal from '@/components/shared/modal/ScalableModal.vue'
import VueDatePicker from '@vuepic/vue-datepicker';

export default {
    name: 'DashboardView',
    components: {
        CFAFooter,
        CFANav,
        DoughnutChart1,
        Table,
        //TableExpend,
        ScalableModal,
        VueDatePicker
    },
    data() {
        return {
            waitingAPIResponse: true,
            result: [],
            resultColumns: ['Nom & Prénoms', 'Email', 'Position', 'Telephone', 'Statut', 'Action'],
            //resultColumns: ['Nom & Prénoms', 'Email', 'Position', 'Telephone', 'Pays', 'Statut', 'Action'],
            fields: [],
            //fieldsColumns: ['Nom', 'Groupe', 'Actions'],
            fieldsColumns: ['Nom', 'Groupe', 'Status'],
            // fieldsColumns: ['Nom', 'Date de démarrage', 'Date de fin', 'Page', 'Template', 'Groupe', 'Actions'],
            fieldsToListen: ['Actions'],
            resultToListen: ['Action'],
            //groupDetails: null,
            groupDetails: { data: {} },
            groupDetailsData: null,
            emailsEnvoyes: 0,
            emailsOuverts: 0,
            liensCliques: 0,
            donnesSoumises: 0,
            emailsSignales: 0,
            groupId: "",
            groupLength: 0,
            groupN: "",
            groupName: "",
            exitEditModal: null,
            openUpdateModal: false,
            tempUpdateID: null,
            updateDataPayload: null,
            templateData: [],
            templateData1: [],
            newCampaignDateStart: null,
            newCampaignDateEnd: null,
            allGroups: [],
            newGroup: "",
            templateName: "",
            newTemp: "",
            allTemplates: [],
            newPage: "",
            pageName: "",
            allLandings: [],
            launchDate: null,
            launchD: null,
            startDate: null,
            startDateD: null,
            status: "",
            campaignName: "",
            orgName: "",
            date: "",
            hour: "",
            dateEnd: "",
            hourEnd: "",
            summary: "",
            openShowDetails: false,
            groupResultDetails: [],
            groupResultActivityDetails: [],
            exitUserShowModal: false,
            currentUserDetail: null,
            currentUserDetailActions: {
                email_sent: [],
                email_opened: [],
                clicked_link: [] 
            }
        }
    },
    computed: {
        filteredTemplate() {
            return this.allTemplates.filter(temp => temp.name !== this.updateDataPayload.template.name);
        },
        filteredLandings() {
            return this.templateData1.filter(land => land.name !== this.updateDataPayload.page.name);
        },
        filteredGroups() {
            return this.allGroups.filter(group => group.name !== this.updateDataPayload.groups[0].name);
        },
        userDetailStatus() {
            if (this.currentUserDetail) {
                return {
                    isSent : (this.currentUserDetail.status == "Email Sent" || this.currentUserDetail.status == "Email Opened" || this.currentUserDetail.status == "Clicked Link"),
                    isOpened: (this.currentUserDetail.status == "Email Opened" || this.currentUserDetail.status == "Clicked Link"),
                    isClicked: (this.currentUserDetail.status == "Clicked Link"),
                    isSubmitted: (this.currentUserDetail.status == "Submited")
                }
            }
            return {
                isSent: false,
                isOpened: false,
                isClicked: false,
                isSubmitted: false
            }
        },
        totalStats () {
            if (this.summary != "") {
                return this.summary.stats.total
            }
            return 0

        },
        formatCampaignStatus () {
            if (this.groupDetails && this.groupDetails != "") {
                return `${this.groupDetails.status == "created" ? `<div><span class="badge rounded-pill badge-danger cfa-bg-secondary cfa-btn-gold p-2 mw-status">En ATTENTE DE VALIDATION</span></div>` : (this.groupDetails.status == "canceled" ? `<div><span class="badge rounded-pill badge-danger cfa-bg-secondary cfa-btn-gold p-2 mw-status">REJECTED</span></div>` : (this.groupDetails.status == "Completed" ? `<div><span class="badge rounded-pill badge-success cfa-bg-danger mw-status cfa-btn-gold p-2">TERMINE</span></div>`: `<div><span class="badge rounded-pill badge-success cfa-bg-danger mw-status cfa-btn-gold p-2">EN COURS</span></div>`))}`
            }
            return ""
        } 
    },
    methods: {
        formatDate(date) {
            const formattedDate = new Date(date).toLocaleString();
            return formattedDate;
        },
        formatDateToLocal(dateTime, locale = 'default') {
            const date = new Date(dateTime);
            return date.toLocaleDateString(locale, { year: 'numeric', month: '2-digit', day: '2-digit' });
        },
        formatStatPercent(rawNumber) {
            //return 100
            return Math.ceil((rawNumber * 100)/this.summary.stats.total)
        },
        getAllTemplates() {
            getAPI.get(`phishing/declaration-page-template/`)
                .then(response => {
                    if (response.status == 200) {
                        this.allTemplates = response.data.data;
                    }
                    else {
                        //let msg = 'Oups ! something went wrong.'
                        //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                    }
                    setTimeout(() => {
                        this.waitingAPITemplate = false
                    }, 2000);
                })
                .catch(error => {
                    if (error.response) {
                        //this.alertString = `Aucun template disponible |alert-no|10000::`+Date.now()
                    }
                    else if (error.request) {
                        //let msg = 'The request was made but no response was received. Please check your network.'
                        //this.alertString = `${msg}|alert-no|8000::`+Date.now()
                    }
                    else {
                        //let msg = 'Oups ! something went wrong.'
                        //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                    }
                    this.waitingAPITemplate = false
                })
        },
        getTemplate() {
            this.waitingAPITemplate = true
            getAPI.get(`phishing/declaration-page-template/`)
                .then(response => {
                    if (response.status == 200) {
                        this.templateData = response.data.data;
                        this.templateName = this.templateData[0].name
                    }
                    else {
                        //let msg = 'Oups ! something went wrong.'
                        //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                    }
                    setTimeout(() => {
                        this.waitingAPITemplate = false
                    }, 2000);
                })
                .catch(error => {
                    if (error.response) {
                        //this.alertString = `Aucun template disponible |alert-no|10000::`+Date.now()
                    }
                    else if (error.request) {
                        //let msg = 'The request was made but no response was received. Please check your network.'
                        //this.alertString = `${msg}|alert-no|8000::`+Date.now()
                    }
                    else {
                        //let msg = 'Oups ! something went wrong.'
                        //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                    }
                    this.waitingAPITemplate = false
                })
        },
        getLanding() {
            this.waitingAPITemplate = true
            getAPI.get(`phishing/landing-page/`)
                .then(response => {
                    if (response.status == 200) {
                        this.templateData1 = response.data.data;
                        this.pageName = this.templateData1[0].name
                    }
                    else {
                        //let msg = 'Oups ! something went wrong.'
                        //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                    }
                    setTimeout(() => {
                        this.waitingAPITemplate = false
                    }, 2000);
                })
                .catch(error => {
                    if (error.response) {
                        //this.alertString = `Vous n'appartenez à aucune organisation|alert-no|10000::`+Date.now()
                    }
                    else if (error.request) {
                        //let msg = 'The request was made but no response was received. Please check your network.'
                        //this.alertString = `${msg}|alert-no|8000::`+Date.now()
                    }
                    else {
                        //let msg = 'Oups ! something went wrong.'
                        //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                    }
                    this.waitingAPITemplate = false
                })
        },
        getLandings() {
            getAPI.get(`phishing/landing-page/`)
                .then(response => {
                    if (response.status == 200) {
                        this.allLandings = response.data.data;
                    }
                    else {
                        //let msg = 'Oups ! something went wrong.'
                        //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                    }
                    setTimeout(() => {
                        this.waitingAPITemplate = false
                    }, 2000);
                })
                .catch(error => {
                    if (error.response) {
                        //this.alertString = `Vous n'appartenez à aucune organisation|alert-no|10000::`+Date.now()
                    }
                    else if (error.request) {
                        //let msg = 'The request was made but no response was received. Please check your network.'
                        //this.alertString = `${msg}|alert-no|8000::`+Date.now()
                    }
                    else {
                        //let msg = 'Oups ! something went wrong.'
                        //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                    }
                    this.waitingAPITemplate = false
                })
        },
        updateData(pos) {
            this.updateDataPayload = pos.detail
            this.openUpdateModal = true
        },
        getCallToAction(payload) {
            console.log("payload")
            if (payload.action_type === 'edit') {
                getAPI.get(`phishing/campaign/${this.$route.params.id}/`)
                    .then(response => {
                        if (response.status === 200) {
                            let data = response.data.data
                            this.updateData(data);
                            console.log("Daaaaaaaaaaataaaaaaaaaaaaaa: ", data)
                        }
                        else {
                            //let msg = 'Oups ! something went wrong.'
                            //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                        }
                    })
                    .catch(error => {
                        if (error.response) {
                            //this.alertString = `${error.response.data[0]}|alert-no|10000::`+Date.now()
                        }
                        else if (error.request) {
                            //let msg = 'The request was made but no response was received. Please check your network.'
                            //this.alertString = `${msg}|alert-no|8000::`+Date.now()
                        }
                        else {
                            //let msg = 'Oups ! something went wrong.'
                            //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                        }
                    })
            } else if (payload.action_type === "details") {
                console.log(payload)
                this.currentUserDetail = this.groupResultDetails.filter(userDetail => userDetail.id == payload.id)[0]
                console.log(this.currentUserDetail)
                this.getCurrentUserDetailStatus(["email_sent", "email_opened", "link_clicked"])
                this.openShowDetails = true
            } else {
                alert("nope")
            }
        },
        confirmUpdate() {
            const group = [];
            group.push(this.newGroup || this.updateDataPayload.groups[0].id);
            getAPI.put(`phishing/campaign/${this.$route.params.id}/`, {
                name: this.updateDataPayload.name,
                page: this.newPage || this.updateDataPayload.page.id,
                template: this.newTemp || this.updateDataPayload.template.id,
                //smtp: this.updateDataPayload.smtp.id,
                groups: group,
                send_by_date: this.updateDataPayload.send_by_date,
                launch_date: this.updateDataPayload.launch_date,
                //send_by_date: this.newCampaignDateEnd,
                //lauch_date: this.newCampaignDateStart,
                //url: this.updateDataPayload.url,
                organisation: this.updateDataPayload.organisation.id,
                status: this.updateDataPayload.status,
                is_validated_by_admin: false
            })
                .then(response => {
                    if (response.status === 200) {
                        let data = response.data.data
                        this.updateData(data);

                        this.getCampaignDetails();
                        //let msg = 'Campaign updated successfully !'
                        //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                        this.exitEditModal = true
                    }
                    else {
                        //let msg = 'Oups ! something went wrong.'
                        //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                        this.exitEditModal = true
                    }
                })
                .catch(error => {
                    this.exitEditModal = true
                    if (error.response) {
                        //this.alertString = `${error.response.data[0]}|alert-no|10000::`+Date.now()
                    }
                    else if (error.request) {
                        //let msg = 'The request was made but no response was received. Please check your network.'
                        //this.alertString = `${msg}|alert-no|8000::`+Date.now()
                    }
                    else {
                        //let msg = 'Oups ! something went wrong.'
                        //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                    }
                })
        },
        convertDate(dateString, dateOnly = false) {
            const date = new Date(dateString);
            const formattedDate = date.toLocaleDateString();
            const formattedTime = date.toLocaleTimeString();
            if (!dateOnly) {
                return `<b>${formattedDate} à ${formattedTime}</b>`;
            }
            return `<b>${formattedDate}</b>`;
        },
        getResult() {
            this.waitingAPIResponse = true
            getAPI.get(`phishing/campaign/${this.$route.params.id}/`)
                .then(response => {
                    if (response.status == 200) {
                        this.groupDetails = response.data.data.details;

                        
                    }
                    else {
                        //let msg = 'Oups ! something went wrong.'
                        //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                    }
                    setTimeout(() => {
                        this.waitingAPIResponse = false
                    }, 2000);
                })
                .catch(error => {
                    if (error.response) {
                        //this.alertString = `Vous n'appartenez à aucune organisation|alert-no|10000::`+Date.now()
                    }
                    else if (error.request) {
                        //let msg = 'The request was made but no response was received. Please check your network.'
                        //this.alertString = `${msg}|alert-no|8000::`+Date.now()
                    }
                    else {
                        //let msg = 'Oups ! something went wrong.'
                        //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                    }
                    this.waitingAPIResponse = false
                })

            //   {
            //       'Nom & Prénoms': 'Généreux Akotenou',
            //       'Email': 'genereux.akotenou@gemail.com',
            //       'Position': 'Eng FullStack',
            //       'Statut':"<button style='width: 100%' class='btn cfa-btn-primary disable'>Email envoyé</button>",
            //       'Reported': ''
            //   }
            setTimeout(() => {
                // this.waitingAPIResponse = false
            }, 2000)
        },
        
        getAllGroups() {
            getAPI.get(`groupes/`)
                .then(response => {
                    if (response.status == 200) {
                        this.allGroups = response.data.data
                        console.log("reponse: ", this.allGroups)
                    }
                    else {
                        //let msg = 'Oups ! something went wrong.'
                        //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                    }
                    setTimeout(() => {
                        this.waitingAPIResponse = false
                    }, 2000);
                })
                .catch(error => {
                    if (error.response) {
                        //this.alertString = `Vous n'appartenez à aucune organisation|alert-no|10000::`+Date.now()
                    }
                    else if (error.request) {
                        //let msg = 'The request was made but no response was received. Please check your network.'
                        //this.alertString = `${msg}|alert-no|8000::`+Date.now()
                    }
                    else {
                        //let msg = 'Oups ! something went wrong.'
                        //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                    }
                    this.waitingAPIResponse = false
                })
        },
        splitGroup(group) {
            const parties = group.split("_");
            const gpName = parties[0];
            return gpName;
        },
        formatDateForCampaign() {
            const dateHeure = this.launchD.split(" ");
            return dateHeure;
        },
        formatDateForCampaign2() {
            const dateHeure = this.startDateD.split(" ");
            return dateHeure;
        },
        async getCampaignDetails() {
            this.waitingAPIResponse = true
            return await getAPI.get(`phishing/campaign/${this.$route.params.id}`)
                .then(response => {
                    console.log(response)
                    if (response.status == 200) {
                        // stats
                        //this.summary = response.data.data.summary;
                        //console.log('summary', this.summary);
                        if (response.data.data.detail.statistic) {
                            let statistic = response.data.data.detail.statistic
                            this.summary = {
                                stats: {
                                    total: statistic.length,
                                    sent: this.getStatsCount(statistic, "Email Sent" ),
                                    opened: this.getStatsCount(statistic, "Email Opened" ),
                                    clicked: this.getStatsCount(statistic, "Link Clicked" ),
                                    submitted_data: this.getStatsCount(statistic, "Submitted data" ),
                                    email_reported: this.getStatsCount(statistic, "Email Reported" ),
                                },
                                launch_date: response.data.data.detail.launch_date
                            }
                        }
                        console.log(this.summary)
                        if(this.summary != "") {
                            this.emailsEnvoyes = this.summary.stats.sent;
                            this.emailsOuverts = this.summary.stats.opened;
                            this.liensCliques = this.summary.stats.clicked;
                            this.donnesSoumises = this.summary.stats.submitted_data;
                            this.emailsSignales = this.summary.stats.email_reported;
                        }
                        
                        this.groupDetails = response.data.data.detail;
                        this.status = this.groupDetails.status
                        this.campaignName = this.groupDetails.name
                        this.groupDetailsData = response.data.data.detail;
                        this.orgName = this.groupDetailsData.name
                        this.groupId = this.groupDetailsData.groups[0].id;
                        const campaignDetails = this.groupDetailsData;
                        const gpName = this.groupDetailsData.groups[0].name
                        this.fields = [];
                        let row = campaignDetails;
                        this.launchD = this.formatDate(row.launch_date, true)
                        this.startDateD = this.formatDate(row.send_by_date, true)
                        this.formatDateForCampaign()
                        this.date = this.formatDateForCampaign()[0]
                        this.hour = this.formatDateForCampaign()[1]
                        this.dateEnd = this.formatDateForCampaign2()[0]
                        this.hourEnd = this.formatDateForCampaign2()[1]
                        let status = `${row.status == "created" ? `<div><span class="badge rounded-pill badge-danger cfa-bg-secondary mw-status">En ATTENTE DE VALIDATION</span></div>` : (row.status == "canceled" ? `<div><span class="badge rounded-pill badge-danger cfa-bg-secondary mw-status">REJECTED</span></div>` : (row.status == "Completed" ? `<div><span class="badge rounded-pill badge-success cfa-bg-danger mw-status">TERMINE</span></div>`: `<div><span class="badge rounded-pill badge-success cfa-bg-danger mw-status">EN COURS</span></div>`))}`
                        this.fields.push({
                            'Nom': `<div>${row.name}</div>`,
                            // 'Date de démarrage':  `${this.convertDate(row.launch_date, true)}`,
                            // 'Date de fin':  `${this.convertDate(row.send_by_date, true)}`,
                            // 'Url':  `${row.url || "NON DEFINI"}`,
                            // 'Page':  `${row.page.name}`,
                            // 'Template':  `${row.template.name}`,
                            //'Smtp':  `${row.smtp?.name || "NON DEFINI"}`,
                            'Groupe': `<div>${this.splitGroup(gpName)}</div>`,
                            //'Actions': `<div>${row.is_validated_by_admin ? `<button disabled title="IMPOSSIBLE DE MODIFIER LA CAMPAGNE" btn-id="${row.id}" btn-action="edit" class="ms-2 btn cfa-btn-secondary btn-action"><i class="fa fa-edit"></i></button>` : `<button title="MODIFIER LES DETAILS DE LA CAMPAGNE" btn-id="${row.id}" btn-action="edit" class="ms-2 btn cfa-btn-secondary btn-action"><i class="fa fa-edit"></i></button>`}</div>`
                            //'Actions': `<button title="MODIFIER LES DETAILS DE LA CAMPAGNE" btn-id="${row.id}" btn-action="edit" class="ms-2 btn cfa-btn-secondary btn-action"><i class="fa fa-edit"></i></button>`,
                            'Status': status
                        });
                        console.log("here")
                        this.getGroupUsers(this.groupDetailsData.groups[0].id)
                        return true
                    }
                    else {
                        //let msg = 'Oups ! something went wrong.'
                        //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                    }
                    setTimeout(() => {
                        this.waitingAPIResponse = false
                    }, 2000);
                    return false
                })
                .catch(error => {
                    if (error.response) {
                        //this.alertString = `Vous n'appartenez à aucune organisation|alert-no|10000::`+Date.now()
                    }
                    else if (error.request) {
                        //let msg = 'The request was made but no response was received. Please check your network.'
                        //this.alertString = `${msg}|alert-no|8000::`+Date.now()
                    }
                    else {
                        //let msg = 'Oups ! something went wrong.'
                        //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                    }
                    this.waitingAPIResponse = false
                    return false
                })
        },
        getGroupUsers(groupId) {
            this.waitingAPIResponse = true
            //console.log(this.groupeId)
            getAPI.get(`groupes/${groupId}`)
                .then(response => {
                    console.log(response.data)
                    if (response.status == 200) {
                        this.groupUsers = response.data;
                        //this.userDetails = this.groupUsers[0].user_detail
                        this.userDetails = this.groupUsers.user_detail

                        // store user campaign result
                        console.log("still here")
                        //this.groupResultDetails = this.groupDetailsData.results.slice(-(this.userDetails.length))
                        this.groupResultDetails = this.groupDetailsData.statistic.slice(-(this.userDetails.length))
                        console.log(this.groupResultDetails)
                        this.groupResultActivityDetails = this.processDetailsResults(this.groupDetailsData.statistic) 
                        console.log(this.groupResultActivityDetails)
                        //this.groupN = this.groupUsers[0].name
                        this.groupN = this.groupUsers.name
                        console.log("Group name: ", this.groupN)
                        this.groupName = this.splitGroup(this.groupN)
                        this.groupLength = this.userDetails.length
                        this.result = [];
                        for (let i = 0; i < this.userDetails.length; i++) {
                            let row = this.userDetails[i];
                            let rowResult = null
                            let rowActivityResult = null
                            if (this.groupResultDetails.length > 0) {
                                rowResult = this.groupResultDetails[i]
                                rowActivityResult = this.groupResultActivityDetails[rowResult.email]
                            }
                            console.log(rowResult, rowActivityResult)
                            console.log(row)
                            let userStatus = "-"
                            if (rowResult) {
                                console.log(rowResult)
                                let actions = this.getStatsActions(rowResult.actions)
                                console.log(actions)
                                let actionStatus = "-"
                                if (actions.length > 0) {
                                    actionStatus  = actions[0].status ? actions[0].status : actions[0].message ? actions[0].message : "-"
                                }
                                
                                if (actionStatus == "Email Sent") {
                                    userStatus = "Email Envoye"
                                } else if (actionStatus == "Email Opened") {
                                    userStatus = "Email Ouvert"
                                } else if (actionStatus == "Clicked Link") {
                                    userStatus = "Lien clicke"
                                } else {
                                    userStatus = "En attente"
                                }
                            }
                            this.result.push({
                                'Nom & Prénoms': `<div>${row.lastname + " " + row.firstname}</div>`,
                                'Email': `<div>${row.email}</div>`,
                                'Position': `<div>${row.role.name == "manager" ? "MANAGER" : "MEMBRE" || "NON DEFINI"}</div>`,
                                'Telephone': `<div>${row.phone ? row.phone : "-" }</div>`,
                                'Pays': `<div>${row.country ? row.country : "-" }</div>`,
                                //'Statut': `<div>${row.status || "-"}</div>`,
                                'Statut': `<div><span class="badge rounded-pill  badge-success">${userStatus}</span></div>`,
                                'Action': `<div><button title="SHOW DETAIL" btn-id="${rowResult ?rowResult.id : row.id}" btn-action="details" class="ms-2 btn cfa-btn-primary btn-action"><i class="fa fa-clipboard">&nbsp;</i></button></div>`
                            });
                        }
                        console.log(this.result)
                        

                    }
                    else {
                        //let msg = 'Oups ! something went wrong.'
                        //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                    }
                    setTimeout(() => {
                        this.waitingAPIResponse = false
                    }, 2000);
                })
                .catch(error => {
                    if (error.response) {
                        //this.alertString = `Vous n'appartenez à aucune organisation|alert-no|10000::`+Date.now()
                    }
                    else if (error.request) {
                        //let msg = 'The request was made but no response was received. Please check your network.'
                        //this.alertString = `${msg}|alert-no|8000::`+Date.now()
                    }
                    else {
                        //let msg = 'Oups ! something went wrong.'
                        //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                    }
                    this.waitingAPIResponse = false
                })
        },
        downloadReport() {
            let now = new Date()
            let endDate = new Date(this.groupDetailsData.send_by_date)
            if (now > endDate) {
                getAPI.get(`phishing/report/${this.$route.params.id}/`).then( response=> {
                    console.log(response)
                    if (response.status == 200) {
                        if (response.data) {
                            /*
                            const fileUrl = response.data.data.url;
                            // Create a temporary anchor element for downloading
                            const link = document.createElement('a');
                            link.href = fileUrl;
                            link.target = '_blank'; // Opens in a new tab (optional)
                            link.download = ''; // The browser will use the default file name from the URL
                            document.body.appendChild(link);
                            link.click();
                            // Cleanup
                            document.body.removeChild(link);
                            */
                            const blob = new Blob([response.data], { type: 'application/pdf' });
                            const url = window.URL.createObjectURL(blob);
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', `${this.campaignName}_rapport.pdf`);
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                            window.URL.revokeObjectURL(url);
                        }
                    }
                })
                .catch(error => {
                    console.log(error)
                })
            } else {
                //alert("Campaign not complete")
                this.$swal({
                    title: 'Rapport de campagne',
                    text: `Votre campagne est toujours en cours. Veuillez patientez jusqu'au ${this.formatDateToLocal(this.groupDetailsData.send_by_date)}`,
                    type: 'warning',
                    showCancelButton: false,
                    confirmButtonText: "Fermer"
                });
            }
        },
        processDetailsResults(data) {
            // Step 1: Remove exact duplicates by converting to a Map with JSON string as key
            const uniqueItems = Array.from(new Map(data.map(item => [JSON.stringify(item), item])).values());

            // Step 2: Group by email and sort each group by created_at
            const groupedByEmail = uniqueItems.reduce((groups, item) => {
                const email = item.email;
                if (!groups[email]) {
                    groups[email] = [];
                }
                groups[email].push(item);
                return groups;
            }, {});

            // Step 3: Process each group
            for (const email in groupedByEmail) {
                // Step 3a: Remove duplicate statuses within the group
                const seenStatuses = new Set();
                groupedByEmail[email] = groupedByEmail[email].filter(item => {
                    if (seenStatuses.has(item.status)) {
                        return false; // Duplicate status
                    }
                    seenStatuses.add(item.status);
                    return true;
                });

                // Step 3b: Sort the group by created_at
                groupedByEmail[email].sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
            }

            return groupedByEmail;
        },
        getStatsCount(stats, status=false) {
            let count = 0
            for (let i = 0; i < stats.length; i++) {
                let stat = stats[i]
                //console.log(stat)
                // Replace single quotes with double quotes
                let validAction = stat.actions[0].replace(/'/g, '"');
                let action = JSON.parse(validAction)
                //console.log(action)
                
                if (action.status.toLowerCase() == status.toLowerCase()) {
                    count++
                }
            }
            
            return count
        },
        getStatsActions(statsAction) {
            let events = []
            for (let i = 0; i < statsAction.length; i++) {
                let statAction = statsAction[i].replace(/'/g, '"')
                
                //console.log(statAction)
                try {
                    let action = JSON.parse(statAction)
                    events.push(action)
                    //console.log(action)
                } catch (error) {
                    console.log("no passing")
                }
                
            }
            return events
        },
        getCurrentUserDetailStatus(status) {
            console.log(status)
            if (this.currentUserDetail) {
                let statsAction = this.getStatsActions(this.currentUserDetail.actions)
                console.log(statsAction)
                statsAction.forEach(statAct => {
                    //
                    if (statAct.status == "Email Sent" || statAct.status == "Clicked Link" || statAct.status == "Email Opened") {
                        this.currentUserDetailActions.email_sent = [true, this.formatDateToLocal(statAct.timestamp)]
                    } 
                    if (statAct.status == "Clicked Link" || statAct.status == "Email Opened") {
                        this.currentUserDetailActions.email_opened = [true, this.formatDateToLocal(statAct.timestamp)]
                    }
                    if (statAct.status == "Clicked Link") {
                        this.currentUserDetailActions.clicked_link = [true, this.formatDateToLocal(statAct.timestamp)]
                    }
                })
            }
        }
    },
    mounted() {
        //this.getResult();
        this.getCampaignDetails();
        
        this.getTemplate();
        this.getLanding();
        this.getAllGroups();
        this.getAllTemplates();
        //this.getLandings();
    }
}
</script>

<style scoped>
.cfa-page {}

.cfa-block {
    background-color: #fff;
    border-radius: 0.5em;
    padding: .5em 1em;
    box-shadow: -1px 1px 29px 20px rgba(222, 234, 250, 0.87);
    -webkit-box-shadow: -1px 1px 29px 20px rgba(222, 234, 250, 0.87);
    -moz-box-shadow: -1px 1px 29px 20px rgba(222, 234, 250, 0.87);
}

.gFolder-container {
    background-color: var(--primary-light-color-2);
    border-radius: 0.5em;
    height: 4.3em;
    width: 4.6em;
    margin-right: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
}

.gBlock {
    align-items: center;
    justify-content: start;
    border-radius: 0.6em;
    padding-left: 2em;
    padding-right: 2em;
    height: 8em !important;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}

.gBlock div:last-of-type p {
    margin: 0;
}

.gBlock div:last-of-type p:first-of-type {
    font-weight: 400;
    font-size: 1.4em;
}
</style>
