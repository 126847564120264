<template>
    <div class="page-auth">
        <div class="register-page">
            <div class="hidden bg-spect lg:flex flex-col items-center justify-evenly">
                <img class="" src="../../assets/auth/images/spec-brand-white.svg"
                        alt="cyberforceacademy logo"  >
                <img class="" src="../../assets/auth/images/icon-auth-banner.svg"
                alt="cyberforceacademy logo" >

                <p class="text-white text-2xl text-center w-3/4 xl:w-full ">Placez l’humain au coeur de votre Stratégie
                    de <span class="text-accent">Cyberdéfense</span></p>
                <!--<img
                    src="#"
                    alt="Image"
                    width="1920"
                    height="1080"
                    class="h-full w-full object-cover dark:brightness-[0.2] dark:grayscale"
                >-->
            </div>
            <!-- <div class="content-form flex items-center justify-center py-12">
                <div class="mx-auto grid w-3/4 gap-6">
                    <div class="grid gap-2 text-center">
                        <h1 class="text-4xl font-[500] text-spect">
                            Inscription
                        </h1>
                        <p class="text-balance">
                            Pour creer un compte veuillez remplir les informations ci-dessous
                        </p>
                    </div>
                    <div class="grid gap-5">
                        <div class="grid gap-2">
                            <label for="email">Email</label>
                            <input id="email" type="text" placeholder="Entrez votre addresse email" required class="input input-bordered ui-input"
                            />
                        </div>
                        <div class="grid gap-2">
                            <label for="name">Nom</label>
                            <input id="name" type="text" placeholder="Entrez votre nom" required class="input input-bordered ui-input"
                            />
                        </div>
                        <div class="grid gap-2">
                            <label for="firstname">Prenom</label>
                            <input id="firstname" type="text" placeholder="Entrez votre prenom" required class="input input-bordered ui-input"
                            />
                        </div>
                        <div class="grid gap-2">
                            <label for="firstname">Telephone</label>
                            <div class="relative">
                                <country-code class="country-dial absolute top-0 pl-2 country-input" @code="changeCode" />
                                <input id="firstname" type="text" placeholder="Entrez votre numero" required class="input input-bordered ui-input w-full tel-input"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
            <div class="content-form flex items-center justify-center py-4 bg-back">
                <div class="mx-auto grid w-3/4 gap-6 bg-white py-8 px-3 rounded-15 " v-if="!isRegister">
                    
                    <div class="grid gap-2 text-center">
                        <h1 class="text-4xl font-[500] text-spect">
                            Inscription
                        </h1>
                        <p class="text-balance">
                            Pour creer un compte veuillez remplir les informations ci-dessous
                        </p>
                    </div>
                    <form v-if="!isOrganizationStep" @submit.prevent="continueToOrganization" class="grid gap-3 px-4">
                        <!-- Account Type -->
                        <div>
                            <label class="block font-medium text-gray-700 mb-1">Type de compte</label>
                            <div class="flex justify-between mb-6 w-full">
                                <label class="flex items-center">
                                    <input type="radio" v-model="formData.accountType" value="personal" class="form-radio text-accent" disabled />
                                    <span class="ml-2">Personnel</span>
                                </label>
    
                                <label class="flex items-center">
                                    <input type="radio" v-model="formData.accountType" value="business" class="form-radio text-accent" />
                                    <span class="ml-2">Organisation/Entreprise</span>
                                </label>
                            </div>
                        </div>

                        <!-- Email -->
                        <div>
                            <label class="block font-medium text-gray-700 mb-1">Email</label>
                            <input
                            type="email"
                            v-model="formData.email"
                            class="w-full px-4 py-2 input input-bordered ui-input"
                            :class="{ 'ui-input--error': errors.email }"
                            placeholder="Entrez votre adresse email"
                            />
                            <p v-if="errors.email" class="text-red-500 text-sm mt-1">{{ errors.email }}</p>
                        </div>

                        <!-- Name -->
                        <div>
                            <label class="block font-medium text-gray-700 mb-1">Nom</label>
                            <input
                            type="text"
                            v-model="formData.lastName"
                            class="w-full px-4 py-2 input input-bordered ui-input"
                            :class="{ 'ui-input--error': errors.lastName }"
                            placeholder="Entrez votre nom"
                            />
                            <p v-if="errors.lastName" class="text-red-500 text-sm mt-1">{{ errors.lastName }}</p>

                        </div>

                        <!-- First Name -->
                        <div>
                            <label class="block font-medium text-gray-700 mb-1">Prénom</label>
                            <input
                            type="text"
                            v-model="formData.firstName"
                            class="w-full px-4 py-2 input input-bordered ui-input"
                            placeholder="Entrez votre prénom"
                            :class="{ 'ui-input--error': errors.firstName }"
                            />
                            <p v-if="errors.firstName" class="text-red-500 text-sm mt-1">{{ errors.firstName }}</p>

                        </div>

                        <!-- Phone -->
                        <!-- <div>
                            <label class="block text-sm font-medium text-gray-700 mb-1">Téléphone</label>
                            <div class="relative flex">
                            <select 
                                v-model="countryCode"
                                class="absolute left-0 w-24 h-full px-2 border-r border-gray-300 bg-transparent focus:ring-2 focus:ring-[#00E7C3] focus:border-transparent rounded-l-lg"
                            >
                                <option value="+33">🇫🇷 +33</option>
                                <option value="+1">🇺🇸 +1</option>
                                
                            </select>
                            <input
                                type="tel"
                                v-model="phone"
                                class="w-full pl-24 px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#00E7C3] focus:border-transparent"
                                placeholder="Entrez votre numéro"
                            />
                            </div>
                        </div> -->
                        <div class="">
                            <label for="telephone" class="block font-medium text-gray-700 mb-1">Telephone</label>
                            <div class="relative">
                                <country-code class="country-dial absolute top-0 pl-2 country-input" @code="changeCode" />
                                <input id="telephone" type="text" placeholder="Entrez votre numero" required class="input input-bordered w-full tel-input ui-input" v-model="formData.phone" :class="{ 'ui-input--error': errors.phone }" />
                            </div>
                            <p v-if="errors.phone" class="text-red-500 text-sm mt-1">{{ errors.phone }}</p>
                        </div>
                        <!-- Terms -->
                        <div class="flex items-center justify-center">
                            <input
                            type="checkbox"
                            v-model="formData.acceptTerms"
                            class="form-checkbox text-accent"
                            />
                            <span class="ml-2 text-sm text-gray-600">
                            J'accepte les 
                            <a href="#" class="text-accent">CGU</a> et 
                            <a href="#" class="text-accent">politiques de confidentialités</a>
                            </span>
                        </div>

                        <!-- Submit Button -->
                        <button
                            type="submit"
                            class="w-full flex items-center justify-center gap-3 bg-spect text-white py-3 rounded-6 hover:bg-opacity-90 transition-colors" :disabled="isInvalid || isSubmit"
                        >
                            <span class="loading loading-spinner" v-if="isSubmit" ></span>
                            <span>
                                {{ formData.accountType == "personal" ? "S'inscrire" : "Continuez" }}
                            </span>
                        </button>
                        <!-- Login Link -->
                        <div class="text-center text-sm">
                            <span class="text-gray-600">Vous avez un compte? </span>
                            <a href="/" class="text-accent">Connectez-vous ici</a>
                        </div>
                    </form>
                    <form v-else @submit.prevent="handleSubmit" class="space-y-6">
                        <!-- Organization Name -->
                        <div>
                            <label class="block font-medium text-gray-700 mb-1">Nom de votre organisation</label>
                            <input
                            type="text"
                            v-model="formData.organizationName"
                            class="w-full px-4 py-2 input input-bordered ui-input"
                            placeholder="Entrez le nom de votre organisation"
                            :class="{ 'ui-input--error': errors.organizationName }"
                            />
                            <p v-if="errors.organizationName" class="text-red-500 text-sm mt-1">{{ errors.organizationName }}</p>
                        </div>

                        <!-- Business Type -->
                        <div>
                            <label class="block font-medium text-gray-700 mb-1">Type d'organisation</label>
                            <select
                            v-model="formData.businessType"
                            class="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-1 focus:ring-[#d8d8d8] focus:border-transparent"
                            >
                            <option value="">Choisissez le domaine de votre activité</option>
                            <option :value="activity.value" v-for="activity in activityOptions" :key="activity.value" >{{ activity.text }}</option>
                            
                            </select>
                            <p v-if="errors.businessType" class="text-red-500 text-sm mt-1">{{ errors.businessType }}</p>
                        </div>

                        <!-- Submit Button -->
                        <button
                            type="submit"
                            class="w-full bg-spect text-white py-3 rounded-lg hover:bg-opacity-90 transition-colors" :disabled="isInvalid || isSubmit"
                        >
                            <span class="loading loading-spinner" v-if="isSubmit" ></span>
                            <span>
                                S'inscrire
                            </span>
                        </button>

                        <!-- Back Button -->
                        <button
                            type="button"
                            @click="goBack"
                            class="w-full bg-gray-100 text-gray-700 py-3 rounded-lg hover:bg-gray-200 transition-colors mt-4"
                        >
                            Retour
                        </button>

                        <!-- Login Link -->
                        <div class="text-center text-sm">
                            <span class="text-gray-600">Vous avez un compte? </span>
                            <a href="#" class="text-accent">Connectez-vous ici</a>
                        </div>
                    </form>
                </div>
                <div class="mx-auto grid w-3/4 gap-6 bg-white py-8 px-3 rounded-15 " v-else>
                    <div class="flex flex-col justify-center items-center gap-3 ">
                        <p class="text-3xl w-full text-center">
                            Inscription terminee
                        </p>
                        <video width="256" height="256" preload="none" style="background: transparent url('https://cdn-icons-png.flaticon.com/512/8717/8717946.png') 50% 50% / fit no-repeat;" autoplay="autoplay" loop="true" muted="muted" playsinline="" class="" >
                            <source src="https://cdn-icons-mp4.flaticon.com/512/8717/8717946.mp4" type="video/mp4">
                        </video>
                        <label class="w-full text-center">Vérifiez votre boîte email pour activer votre compte. Merci!</label>
                        <div class="text-center">
                            <span class="text-gray-600">J'ai pas reçu le mail&nbsp; </span>
                            <a  class="text-accent cursor-pointer" @click="resentActivationMail" >Renvoyer le mail d'activation</a>
                        </div>
                        <div class="text-center text-sm">
                            <span class="text-gray-600">Vous avez un compte? </span>
                            <a href="/" class="text-accent">Connectez-vous ici</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="toast toast-end my-toast" v-if="isAlert">
            <div class="alert alert-info toast-ctn--info">
                <span>{{ alertMessage }}</span>
            </div>
        </div>
    </div>
</template>

<script>

import countryCode from '../../components/shared/input/countryCode.vue';
import axios from "axios";

export default {
    name: "RegisterView",
    components: {
        countryCode
    },
    setup() {
        
    },
    data() {
        return {
            dialCode:'',
            isOrganizationStep: false,
            activityOptions: [],
            formData: {
                accountType: 'business',
                email: '',
                lastName: '',
                firstName: '',
                countryCode: '+33',
                phone: '',
                phoneNumber: '',
                acceptTerms: false,
                // Organization specific fields
                organizationName: '',
                businessType: '',
                country: ''
            },
            errors: {
                email: null,
                lastName: null,
                firstName: null,
                phone: null,
                acceptTerms: null,
                organizationName: null,
                businessType: null
            },
            isSubmit: false,
            isRegister: false,
            isAlert: false,
            alertMessage: "New message",
            isInvalid: false,
        }
    },
    methods: {
        changeCode (country) {
            //this.dialCode = '(+' + country[2] + ')'
            this.dialCode = '+' + country[2] + ''
            this.formData.country = country[0];
            //this.formData.phoneNumber = this.dialCode  + this.formData.phone;
            console.log(country)
            
            //this.$emit('updateNumber', phone)
        },
        continueToOrganization() {
            // Add validation here if needed
            console.log("here")
            if (this.validateStep1()) {
                if (this.formData.accountType === 'business') {
                    this.isOrganizationStep = true
                } else {
                    this.handleSubmit()
                }
            }
        },
        goBack() {
            this.isOrganizationStep = false
        },
        async handleSubmit () {
            // Handle form submission
            let phoneNumber = this.formData.phone
            this.isSubmit = true
            console.log(this.formData, phoneNumber)
            
            if (this.formData.accountType === 'personal') {
                await axios.post(`${process.env.VUE_APP_BASE_URL}accounts/register/individual`,
                    {
                        email: this.formData.email,
                        firstname: this.formData.firstName,
                        lastname: this.formData.lastName,
                        country: this.formData.country,
                        phone: phoneNumber ,
                    }
                )
                .then(res => {
                    console.log(res)
                    if(res.status == 201) {
                        this.isRegister = true
                        localStorage.setItem('guest_email', this.formData.email);
                    }
                })
                .catch(err => {
                    console.log(err)
                    if (err.response && !err.response.data.success) {
                        if (err.response.data.data.email == "This email is already in use.") {
                            this.errors.email = "L'email est deja utilise"
                        }
                    }
                    this.isSubmit = false
                })
            } else {
                if (this.validateStep2()) {
                    console.log("handling business")
                    await axios.post(`${process.env.VUE_APP_BASE_URL}accounts/register/manager`,
                        {
                            email: this.formData.email,
                            firstname: this.formData.firstName,
                            lastname: this.formData.lastName,
                            country: this.formData.country,
                            phone: phoneNumber ,
                            //id: this.id,
                            organisation: {
                                name: this.formData.organizationName,
                                country: this.formData.country,
                                //address: '',
                                phone: phoneNumber,
                                //email: this.register_org_mail,
                                //website: this.register_org_web,
                                //logo: this.register_org_logo
                            },
                            activity: this.formData.businessType,
                        }
                    ).then(res => {
                        console.log(res)
                        if (res.status == 201) {
                            this.isRegister = true
                            localStorage.setItem('guest_email', this.formData.email);
                        }
                    })
                    .catch(err => {
                        console.log(err)
                        if (err.response && !err.response.data.success) {
                            if (err.response.data.data.email == "This email is already in use.") {
                                this.errors.email = "L'email est deja utilise"
                            }
                            this.goBack()
                        }
                        this.isSubmit = false
                    })
                }
            }
        },
        validateStep1() {
            // Add validation logic for step 1
            this.errors = {
                email: null,
                lastName: null,
                firstName: null,
                phone: null,
                acceptTerms: null,
                organizationName: null,
                businessType: null
            }
            let isValid = true
            if (!this.formData.email || this.formData.email.trim() == "" ) {
                this.errors.email = 'Le champ email est obligatoire.'
                isValid = false
            } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.formData.email)) {
                this.errors.email = 'L\'adresse email n\'est pas valide.'
                isValid = false
            }
            if (!this.formData.lastName || this.formData.lastName.trim() == "") {
                this.errors.lastName = 'Le champ nom est obligatoire.'
                isValid = false
            }

            if (!this.formData.firstName || this.formData.firstName.trim() == "") {
                this.errors.firstName = 'Le champ prenom est obligatoire.'
                isValid = false
            }
            if (!this.formData.phone || this.formData.phone.trim() == "") {
                this.errors.phone = 'Le champ telephone est obligatoire.'
                isValid = false
            } else if (!this.isNumeric(this.formData.phone)) {
                this.errors.phone = 'Le champ telephone doit contenir uniquement des chiffres.'
                isValid = false
            }
            if (!this.formData.acceptTerms) {
                this.errors.acceptTerms = 'Vous devez cochez les termes et conditions'
                isValid = false
                this.$swal({
                    title: 'Terms et Comditions',
                    text: `Vous devez cochez les termes et conditions`,
                    type: 'warning',
                    showCancelButton: false,
                    confirmButtonText: "Fermer"
                });
            }
            return isValid
        },
        validateStep2() {
            // Add validation logic for step 2
            this.errors.organizationName = null
            this.errors.businessType = null
            let isValid = true

            if (!this.formData.organizationName || this.formData.organizationName.trim() == "") {
                this.errors.organizationName = 'Le champ organization est obligatoire.'
                isValid = false
            }
            if (!this.formData.businessType || this.formData.businessType.trim() == "") {
                this.errors.businessType = 'Le champ domain activite est obligatoire.'
                isValid = false
            }
            return isValid
        },
        getActivity() {
        //this.waitingAPIResponse = true;
        //this.waitingAPIResponse2 = true;
        axios.get(`${process.env.VUE_APP_BASE_URL}activity`)
        .then(response => {
            console.log(response)
            if (response.status === 200) {
                const activities = response.data.results;
                //this.staticActivity = activities;
                this.activityOptions = activities.map(activity => ({
                    value: activity.id,
                    text: activity.name
                }));
            } else {
              //let msg = 'Oups ! something went wrong.';
              //this.alertString = `${msg}|alert-no|5000::` + Date.now();
            }
            /* setTimeout(() => {
                this.waitingAPIResponse = false;
                this.waitingAPIResponse2 = false;
            }, 2000); */
        })
        .catch(error => {
          console.log(error)
          if (error.response) {
            //this.alertString = `Activité non disponible|alert-no|10000::` + Date.now();
          } else if (error.request) {
            console.log(error.request)
          } else {
            //let msg = 'Oups ! something went wrong.';
            //this.alertString = `${msg}|alert-no|5000::` + Date.now();
          }
          //this.waitingAPIResponse = false;
          //this.waitingAPIResponse2 = false;
          });
        },
        isNumeric(phoneNumber) {
            return /^[0-9]+$/.test(phoneNumber);
        },
        resentActivationMail () {
            axios.post(`${process.env.VUE_APP_BASE_URL}accounts/resend-email-activate-account/`,
            {
                email: localStorage.guest_email,
            })
            .then(response => {
                console.log(response)
                if(response.status == 200) {
                    this.alertMessage = "Le mail d'activation vous à été envoyé à nouveau. Veuillez consulter votre boite mail." 
                    this.isAlert = true;
                    setTimeout (()=> {
                        this.isAlert = false
                    }, 8000)
                    this.$emit('resent')
                }
                else {
                    //this.displayError('Oups ! something went wrong.', 'alert-no')
                }
            })
            .catch(error => {
            localStorage.removeItem('token')
            if (error.response) {
                this.displayError(error.response.data.message, 'alert-no', 10000)
            }
            else if (error.request) {
                this.displayError('The request was made but no response was received. Please check your network.', 'alert-no', 8000)
            }
            else {
                this.displayError('Oups ! something went wrong.', 'alert-no', 5000)
            }
            this.waitingAPIResponse = false
            })
        },
    },
    mounted () {
        this.getActivity()
    },
    watch: {
        'formData.accountType'(newValue) {
            if (newValue === 'personal') {
                this.isOrganizationStep = false
            }
        }
    }

}
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/tailwinds.scss";
    
</style>