<template>
    <div class="d-flex">
        <!-- [ is_default ? 'selected-plan' : '' ] -->
        <div class="">
            <div class="card course-box">
                <div class="card-body time-block">
                    <div style="font-weight: lighter; text-align: center;">
                        <span class="plan_tag text-uppercase">
                        {{ name }}
                        </span>
                    </div>

                    <!-- <div class="sc-1slfn-2 bFilqo mt-4" style="align-items: baseline; color: #132B4D">
                        <p class="sc-1slfn-3 eGRLiv">{{currency_code}}{{ monthly_price || 0 }}</p>
                        <p class="sc-1slfn-4 tcmDt" style="font-size: 19px; margin-bottom: .5em;">{{ type }} / mois</p>
                        <p class="sc-1slfn-4 tcmDt" style="font-size: 19px; margin-bottom: .5em;">par {{ type }} par mois</p>
                    </div> -->
                    <!--<div class="d-flex align-items-center justify-content-center pb-5 pt-2">
                        <h4 class="mb-0 text-uppercase" style="font-size: 40px !important; line-height: 28px !important; font-weight: 800 !important;">
                            <sup class="f3 color-fg-muted v-align-middle mr-1">{{ currency_code }}</sup>
                            <span class="js-computed-value" data-plan="business">{{ monthly_price||'3.67' }}</span>
                        </h4>
                    </div>
                    <span class="text-normal text-center f6-mktg color-fg-muted">
                    <span class="d-lg-block no-wrap">
                        par {{ type }} par mois
                    </span>
                    </span>-->

                    <!-- <div style="margin: 1em 0px; text-align: left;">
                        <span class="f-light pt-2" style="color: #132B4D">
                        {{ description }}
                        </span>
                    </div> -->



                <div class="d-flex align-items-center justify-content-center pb-4 pt-4">
                    <p style="display: none">{{id}}</p>
                    <!--<button @click="openPopUp(id)" class="btn cfa-btn-primary w-100 cfa-subscription-btn">SOUSCRIRE</button>-->

                    <button @click="openPopUp(id)"  class="btn cfa-btn-primary w-100 cfa-subscription-btn">SOUSCRIRE</button>

                    <!-- <button v-if="isActive == true" @click="off()" class="btn cfa-btn-primary w-100 cfa-subscription-btn" >SOUSCRIRE</button> -->
                </div>


                <!-- FEATURES DESC -->
                    <!--<br><hr><br>-->
                    <!-- <ul class="d-lg-block flex-auto list-style-none text-left rounded-bottom-3 color-bg-subtle px-3 py-2 js-compare-features-item plan_ul_feature" style="padding: 0 !important; padding-bottom: 0.9em !important; color: #132B4D">
                        <li class="position-relative">
                            <div class="d-flex" v-for="feature,i in features" :key="i">
                                <div class="plan_feature">
                                    <div class="plan_feature_inner">
                                        <svg fill="white" id="Tick_inactive" data-name="Tick/inactive" width="10" height="10" viewBox="0 0 10 10"><path id="tick_icon" d="M3.781,7a1.782,1.782,0,0,1-1.229-.49L.227,4.253A.75.75,0,0,1,1.272,3.177L3.589,5.426a.279.279,0,0,0,.205.074A.3.3,0,0,0,4,5.4L8.7.245A.75.75,0,1,1,9.8,1.255L5.111,6.411A1.789,1.789,0,0,1,3.849,7H3.781" transform="translate(0 1.5)" fill="current"></path></svg>
                                    </div>
                                </div>
                                <div>
                                    <p>{{ feature.description }}</p><br/>
                                </div>
                            </div>
                        </li>
                    
                    </ul> -->
                    <!--<div class="course-widget d-flex justify-content-between">
                        <div class="course-icon position-relative">
                            <i class="fa fa-file pentagone_icon"></i>
                            <svg class="fill-icon" style="background: #13dce6;"></svg>
                        </div>
                        <div style="font-weight: lighter">
                            <br>
                            <span class="plan_tag text-uppercase">
                        {{ type }}
                        </span>
                        </div>
                    </div>
                    <br>
                    <div class="d-flex align-items-center">
                        <h4 class="mb-0 text-uppercase">{{ name }}</h4>
                    </div>
                    <div>
                <span class="f-light pt-2">
                    {{ description }}
                </span>
                    </div>
                    <br>
                    <div>
                        <table class="customized-table">
                            <thead class="opc-block">
                            <tr>
                                <th class="no-wrap no-border-right" style="min-width: 100px !important;">Abonement</th>
                                <th class="no-wrap"></th>
                            </tr>
                            </thead>
                            <tbody class="opc-block">
                            <tr class="opc-table-row">
                                <td>Annuel</td>
                                <td>{{ yearly_price }} {{ currency_code }}</td>
                            </tr>
                            <tr class="opc-table-row">
                                <td>Mensuel</td>
                                <td>{{ monthly_price }} {{ currency_code }}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div>
                        <br>
                        <br>
                        <div style="font-weight: lighter" class="d-flex justify-content-start align-items-center">
                            <button @click="initPayment('stripe')" class="btn btn-light" style="border: 1px solid #b7b6b6; border-radius: 15em;">
                                PAYER AVEC&nbsp;
                                <img src="@/assets/img/png/stripe.png" alt="stripe logo" style="height: 1.7em;"/>
                            </button>
                            &nbsp;&nbsp;
                            <button @click="initPayment('feda')" class="btn btn-light" style="border: 1px solid #b7b6b6; border-radius: 15em;">
                                PAYER AVEC&nbsp;
                                <img src="@/assets/img/png/feda.svg" alt="fedapay logo" style="height: 1.45em;"/>
                            </button>
                        </div>
                    </div>
                    <br>
                    <div>
                        <p>NB: Pour toute souscription annuelle par carte bancaire, bénéficiez d'une reduction de 1% sur le montant initial de l'achat.</p>
                    </div>-->
                </div>
                <ul class="square-group">
                    <li class="square-1 warning"></li>
                    <li class="square-1 primary"></li>
                    <li class="square-2 warning1"></li>
                    <li class="square-3 danger"></li>
                    <li class="square-4 light"></li>
                    <li class="square-5 warning"></li>
                    <li class="square-6 success"></li>
                    <li class="square-7 success"></li>
                </ul>
                <div :id="id" :class="{ 'prefetch_overlay': showSubscriptionOptions }">
                    <div v-if="showSubscriptionOptions && subscriptionType===null && planDefaultPrice != null" style="padding: 1em; background: #fff;">
                        <p>Souhaitez-vous une souscription mensuelle ou annuelle ?</p>
                        <br>
                        <div class="d-flex align-items-center justify-content-center">
                            <button @click="handleButtonClick(id)" class="btn cfa-btn-secondary">
                                MENSUELLE ({{ monthly_price }} {{ currency_code }})
                            </button>
                            &nbsp;
                            <button @click="handleButtonClick2(id)" class="btn cfa-btn-secondary">
                                ANNUELLE ({{ yearly_price }} {{ currency_code }})
                            </button>
                        </div>
                    </div>
                </div>
                <transition name="fadeY" appear mode="out-in">
                    <alert v-if="alertMe" :status="gAlertType" :message="gAlertMessage"></alert>
                </transition>
                <scalable-modal :disable-control="true" @callback="addNewPlan" :break="exitAddPlanModal" display-mode="flex"  label="" :uid="'cfa-modal-add-plan-'+id" @close="openModal=false" :open="openModal" :advancedControl="true" validationButtonText="Mettre à jour" exitButtonText="Annuler" class="w-md-50 w-100 payment-modal " :style="'-max-width: 33vw; height: auto; margin-top: 5em; margin-bottom: 2em; background-color: #f0f0f0;'">
                    <template v-slot:content>
                        <div class="d-flex justify-content-center align-items-center plan-pay-content ">
                            <cards-base class="pay-method-card " @click="choosePaymentGateway('stripe')" v-show="false" >
                                <template #content>
                                    <div class="check-method" v-show="selectedGateway === 'stripe'" >
                                        <i class="fa fa-check"></i>
                                    </div>
                                    <h2 class="text-title">Payer Avec</h2>

                                    <img src="@/assets/img/png/stripe.png" alt="stripe logo" />
                                    <p>
                                        Rapide et securise
                                    </p>
                                </template>
                            </cards-base>
                            <!-- <cards-base class="pay-method-card" @click="choosePaymentGateway('fedapay')" >
                                <template #content>
                                    <div class="check-method" v-show="selectedGateway === 'fedapay'" >
                                        <i class="fa fa-check"></i>
                                    </div>
                                    <h2 class="text-title">Payer Avec</h2>

                                    <img src="@/assets/img/png/feda.svg" alt="stripe logo" />
                                    <p>
                                        Rapide et securise
                                    </p>
                                </template>
                            </cards-base> -->
                            <button @click="choosePaymentGateway('stripe')" class="btn btn-light pay-method-btn " :class="{'pay-method-btn--selected': selectedGateway === 'stripe'}" disabled >
                                PAYER AVEC&nbsp;
                                <img src="@/assets/img/png/stripe.png" alt="stripe logo" style="height: 1.7em;"/>
                            </button>
                            &nbsp;&nbsp;
                            <button @click="choosePaymentGateway('fedapay')" class="btn btn-light pay-method-btn" :class="{'pay-method-btn--selected': selectedGateway === 'fedapay'}" >
                                PAYER AVEC&nbsp;
                                <img src="@/assets/img/png/feda.svg" alt="fedapay logo" style="height: 1.45em;"/>
                            </button>
                        </div>
                        <br><br><br>
                        
                        <p style="color: #132B4D">NB: Pour toute souscription annuelle par carte bancaire, bénéficiez d'une reduction de 1% sur le montant initial de l'achat.</p>
                        <br>
                        <div class="mt-2 d-flex align-items-center justify-content-end w-100">
                            <button @click="goToCheckoutPage()" class="btn cfa-btn-primary w-100 cfa-subscription-btn cfa-btn-primary" :class="{'spect-btn-disable': typeof(selectedGateway) != 'string' }" style="" :disabled="typeof(selectedGateway) != 'string' " >SUIVANT</button>
                        </div>
                    </template>
                </scalable-modal>
            </div>
            <!-- <h4 v-if="is_default" class="mb-0 text-uppercase selected-label" style="font-size: 11px !important; z-index: 1000; line-height: 28px !important; font-weight: 800 !important;">PLAN ACTUEL</h4> -->
        </div>
    </div>
</template>

<script>
import {getAPI} from "@/axios-api";
import Alert from '@/components/shared/alert/Alert.vue'
import scalableModal from "@/components/shared/modal/ScalableModal.vue";

import cardsBase from "@/components/shared/cards/CardsBase.vue";
export default {
    name: "planCard",
    props: {
        id: {
            type: String,
            default: '1'
        },
        type: {
            type: String,
            default: 'PLAN 1: ORGANISATION'
        },
        name: {
            type: String,
            default: 'Basic'
        },
        description: {
            type: String,
            default: 'Ce plan vous donne accès à tous les modules sauf à l\'option de lancer un test de phishing'
        },
        currency_code: {
            type: String,
            default: 'FCFA'
        },
        yearly_price: {
            type: String,
            default: '800'
        },
        monthly_price: {
            type: String,
            default: '3.000.34'
        },
        yearly_price_id: {
            type: String,
            default: 'price_1NSdu..'
        },
        monthly_price_id: {
            type: String,
            default: 'price_1NSdu..'
        },
        is_default: {
            type: Boolean,
            default: false
        }
    },
    components: {
        scalableModal,
        Alert,
        cardsBase
    },
    data() {
        return {
            alertMe: false,
            gAlertMessage: '',
            gAlertType: '',
            subscriptionType: "monthly",
            //subscriptionType: null,
            openModal: false,
            exitAddPlanModal: null,
            selectedGateway: null,
            features: [],
          plans: [],
          selectedPlanId: null,
          planDefaultPrice: null,
          planPriceYear: null,
          planPriceMonth: null,
          showSubscriptionOptions: false,
          isActive: false,
        }
    },
    methods: {
      getPlans () {
        this.waitingAPIResponse = true
        getAPI.get(`plans/`)
            .then(response => {
              if(response.status == 200) {
                this.plans = response.data.results
                /*for(let i=0; i<plans.length; i++) {
                    let row = plans[i]
                    let autres = `<div class="d-flex align-items-center">
                          <button btn-id="${row.id}" btn-action="delete" class="ms-2 btn cfa-btn-danger btn-action"><i class="fa fa-update"></i> ACTIVER CE PLAN</button>
                     </div>`

                    let modules = '<div class="text-start col-12 d-flex flex-wrap">';
                    for (let j = 0; j < row.modules.length; j++) {
                        modules += `<div style="cursor: pointer; border: 1px solid #ccc; padding: 0.5em; margin: 0.5em; border-radius: 0.25em;">Module ${row.list_modules[j].number}</div>`;
                    }
                    modules += '</div>'

                    if(row.type_plan == sessionStorage.type_account) {
                        this.plans.push({'N°': (i+1),'Nom': row.name, 'Prix': this.formatPrice(row.price), 'Capacité': row.duration, 'Type': row.type_plan, 'Modules': modules, 'Autres':autres, name: row.id})
                    }
                }*/
              }
              else {
                //let msg = 'Oups ! something went wrong.'
                //this.alertString = `${msg}|alert-no|5000::`+Date.now()
              }
              setTimeout(() => {
                this.waitingAPIResponse = false
              }, 2000);
            })
            .catch(error => {
              if (error.response) {
                //this.alertString = `${error.response.data.message}|alert-no|10000::`+Date.now()
              }
              else if (error.request) {
                //let msg = 'The request was made but no response was received. Please check your network.'
                //this.alertString = `${msg}|alert-no|8000::`+Date.now()
              }
              else {
                //let msg = 'Oups ! something went wrong.'
                //this.alertString = `${msg}|alert-no|5000::`+Date.now()
              }
              this.waitingAPIResponse = false
            })
      },
        isOrg () {
            return sessionStorage.getItem('type_account')  === 'organisation';
        },
        choosePaymentGateway (label) {
            this.selectedGateway = label;
        },
        openPopUp(planId) {
          this.openModal = true;
          this.selectedPlanId = planId;
          for (const plan of this.plans) {
            if (plan.id === this.selectedPlanId) {
              this.planDefaultPrice = plan.default_price != null ? plan.id_default_price : null;
              this.planPriceYear = plan.id_price_year;
              this.planPriceMonth = plan.id_price_month;
              break;
            }
          }
          this.showSubscriptionOptions = false;
          console.log("Default price: ", this.planDefaultPrice)
        },
        off() {
          this.openModal = false;
          this.$swal({
              title: 'Vous avez une souscription valide !',
              //text: ("Your have a valid subscription"),
              type: 'warning',
              showCancelButton: false,
              confirmButtonText: `<a href=#" style="text-decoration: none; font-weight: 400; color: white;">Fermer</a>`,
          });
        },
      subscribeClicked() {
        this.showSubscriptionOptions = true;
      },
        goToCheckoutPage() {
            if(this.selectedGateway===null) {
                this.$emit('alert', "You have to select a payment gateway before proceed !" +
                    "");
                return;
            }

            if(this.selectedGateway==="stripe") {
                getAPI.post(`transactions/stripe`, {
                    user: sessionStorage.user,
                    plan: this.id,
                    //price: this.planDefaultPrice !== null ? this.planDefaultPrice : (this.subscriptionType === "monthly" ? this.planPriceMonth : this.planPriceYear)
                    price: this.planDefaultPrice !== null ? this.planDefaultPrice : this.planPriceMonth
                }).then(response => {
                    
                    console.log("Echec stripe")
                    if (response.response) {
                      this.$emit('alert', response.response.data.plan);
                      //this.displayresponse(response.response.data.message, 'alert-no');
                    } else if (response.request) {
                        this.$emit('alert', 'You have a valid subscription !');
                      //this.$emit('alert', 'The request was made but no response was received. Please check your network.');
                    } else {
                      this.$emit('alert', 'Oups ! something went wrong.');
                      this.displayError('Oups ! something went wrong.', 'alert-no');
                    }
                    setTimeout(() => {
                      document.getElementById(this.id).style.display = 'none';
                    }, 2000);
                    })
                    .catch(error => {
                        let data = error.response.data.data

                        console.log(error.response)

                        // PROCESSING
                        if (Array.isArray(data)) {
                            // TODO: JEDIEL -> LABEL A CORRRIGER; STATUS CODE CUSTOM REQUIS
                            if (data.includes('You are valid subscription')) {
                                this.$emit('alert', 'You have a valid subscription !');
                            }
                        } else {
                            window.location.href = data.url;
                        }
                    setTimeout(() => {
                        document.getElementById(this.id).style.display = 'none';
                    }, 2000);
                })
            }
            else {
              getAPI.post(`transactions/feda`, {
                user: sessionStorage.user,
                plan: this.id,
              })
              .then(response => {
                console.log("Echec feda")
                if (response.response) {
                      this.$emit('alert', response.response.data.plan);
                      //this.displayresponse(response.response.data.message, 'alert-no');
                    } else if (response.request) {

                      this.$emit('alert', 'The request was made but no response was received. Please check your network.');
                    } else {
                      this.$emit('alert', 'Oups ! something went wrong.');
                      this.displayError('Oups ! something went wrong.', 'alert-no');
                    }
                    setTimeout(() => {
                      document.getElementById(this.id).style.display = 'none';
                    }, 2000);
              })
              .catch(error => {
                console.log("Succès feda")
                let data = error.response.data.data

                // PROCESSING
                if (Array.isArray(data)) {
                    // TODO: JEDIEL -> LABEL A CORRRIGER; STATUS CODE CUSTOM REQUIS
                if (data.includes('You are available subscription')) {
                    this.$emit('alert', 'Vous avez déjà activé cette souscription.');
                }
                } else {
                window.location.href = data.url;
                
                }
                setTimeout(() => {
                    document.getElementById(this.id).style.display = 'none';
                }, 2000);
                })
            }
        },

        getFedaConfirmationPayment() {
          this.waitingAPIResponse = true
          const id = this.$route.query.id;

          if (id) {
            getAPI.post(`transactions/feda-confirmation`, { code: id })
              .then(response => {
                if (response.status === 200) {
                  console.log("Activation of subscription via feda successful !")
                } else {
                    console.log("Confirmation failed !")
                }
              })
              .catch(error => {
                // Gérez les erreurs de la requête API
                console.error("Erreur lors de la requête API : " + error);
              });
          } else {
            console.error("The 'id' parameter is missing from the URL.");
          }
        },
        retrieveUserSubscription () {
          this.waitingAPIResponse = true 
          getAPI.get(`subscriptions/find-subscription`).then(response => {
          //getAPI.get(`subscriptions?user_id=${sessionStorage.user}`).then(response => {
            if(response.status == 200) {
              this.subscriptionData = response.data.data

              this.isActive = this.subscriptionData.is_activated;

              this.subscriptions = []

              //let row = this.subscriptionData
              let row = this.subscriptionData;
              //console.log("Data: ", row)

              let statut = (row.is_activated) ? `<button btn-id="${row.id}" btn-action="deactivate" class="ms-2 btn btn-success btn-action" style="background-color: var(--primary-color)"><i class="fa fa-check"> &nbsp;&nbsp;</i>PLAN ACTIF</button>` : `<button btn-id="${row.id}" btn-action="activate" class="ms-2 btn cfa-btn-danger btn-action"><i class="fa fa-lock"></i> PLAN INACTIF</button>`;
              this.subscriptions.push({'PLAN': `<span style="font-weight: 400;">${/*row.plan.name*/"plan_name"}</span>`, 'MONTANT': 'montant' + " XOF", 'DATE DEBUT': this.convertDate(row.created_at, 'date_only'), 'JOURS RESTANTS': row.remain_days, 'STATUT': statut,name: row.id, id: row.id})

              // subscriptionsColumns: ['PLAN', 'MONTANT', 'MODULES', 'DATE DEBUT', 'DATE FIN', 'STATUT'],
              // for(let i=0; i<this.subscriptionData.length; i++) {
              //   let row = this.subscriptionData[i]

              //   // let modules = '<div class="text-start col-12 d-flex flex-wrap">';
              //   // for (let j = 0; j < row.list_modules.length; j++) {
              //   //   modules += `<div style="cursor: pointer; border: 1px solid #ccc; padding: 0.5em; margin: 0.5em; border-radius: 0.25em;">Module ${row.list_modules[j].number}</div>`;
              //   // }
              //   // modules += '</div>'

              //   // let statut = (row.is_activated) ? `<button btn-id="${row.id}" btn-action="deactivate" class="ms-2 btn btn-success btn-action" style="background-color: var(--primary-color)"><i class="fa fa-check"> &nbsp;&nbsp;</i>PLAN ACTIF</button>` : `<button btn-id="${row.id}" btn-action="activate" class="ms-2 btn cfa-btn-danger btn-action"><i class="fa fa-lock"></i> PLAN INACTIF</button>`;
              //   // // classify and add to array
              //   // this.subscriptions.push({'N°': (i+1), 'PLAN': `<span style="font-weight: 400;">${row.plan.name}</span>`, 'MONTANT': "$" + row.plan.price_month + " / mois", 'MODULES': modules, 'DATE DEBUT': this.convertDate(row.start_date, 'date_only'), 'DATE FIN': this.convertDate(row.end_date, 'date_only'), 'STATUT': statut,name: row.id, id: row.id})
                
              //   let statut = (row.is_activated) ? `<button btn-id="${row.id}" btn-action="deactivate" class="ms-2 btn btn-success btn-action" style="background-color: var(--primary-color)"><i class="fa fa-check"> &nbsp;&nbsp;</i>PLAN ACTIF</button>` : `<button btn-id="${row.id}" btn-action="activate" class="ms-2 btn cfa-btn-danger btn-action"><i class="fa fa-lock"></i> PLAN INACTIF</button>`;
              //   // classify and add to array
              //   this.subscriptions.push({'N°': (i+1), 'DATE DE CREATION': `<span style="font-weight: 400;">${row.created_at}</span>`, 'JOURS RESTANTS': `<span style="font-weight: 400;">${row.remain_days}</span>`, 'STATUT': statut,name: row.id, id: row.id})
              // }
//              console.log(this.subscriptions)
          }
            else {
              //let msg = 'Oups ! Vous n\'avez aucune souscription.'
              //this.alertString = `${msg}|alert-no|5000::`+Date.now()
            }
            setTimeout(() => {
              this.waitingAPIResponse = false
            }, 2000);
          }).catch(error => {
            if (error.response) {
              //this.alertString = `${error.response.data.message}|alert-no|10000::`+Date.now()
            }
            else if (error.request) {
              //let msg = 'The request was made but no response was received. Please check your network.'
              //this.alertString = `${msg}|alert-no|8000::`+Date.now()
            }
            else {
              //let msg = 'Oups ! something went wrong.'
              //this.alertString = `${msg}|alert-no|5000::`+Date.now()
            }
            this.waitingAPIResponse = false
          })
        },
        displayError(error, type='alert-ok') {
            this.gAlertMessage = error
            this.gAlertType = type
            this.alertMe = true
            setTimeout(() => {
                this.alertMe = false
            }, 5000);
        },
        initPayment (type) {
            if(type==="stripe") {
                // LOADING
                document.getElementById(this.id).style.display='flex';
            }
            else{
                alert('feature not yet implemented !')
            }
        },
        loadRemoteLink (link) {
            let a = document.createElement('a');
            a.setAttribute('href', link);
            a.setAttribute('target', '_blank');
            a.click();
            a.remove();
        },
        addNewPlan () {
            alert('oo')
        },
        getFeatures () {
            this.waitingAPIResponse = true
            this.waitingAPIResponse2 = true;
            getAPI.get(`features-plan/all`)
            .then(response => {
                if (response.status === 200) {
                    let featuresData = response.data;
                    this.features = featuresData;
                    //console.log("Features: ", featuresData)
                }
                else {
                    //let msg = 'Oups ! something went wrong.'
                    //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                    }
                    setTimeout(() => {
                    this.waitingAPIResponse = false
                    this.waitingAPIResponse2 = false;
                }, 2000);
            })
            .catch(error => {
                if (error.response) {
                    //this.alertString = `Vous n'appartenez à aucune organisation|alert-no|10000::`+Date.now()
                }
                else if (error.request) {
                    //let msg = 'The request was made but no response was received. Please check your network.'
                    //this.alertString = `${msg}|alert-no|8000::`+Date.now()
                }
                else {
                    //let msg = 'Oups ! something went wrong.'
                    //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                }
                this.waitingAPIResponse = false;
                this.waitingAPIResponse2 = false;
            })
            }
    },
    mounted() {
        this.isOrg();
        this.getFeatures();
        this.getFedaConfirmationPayment();
        this.getPlans();
        this.retrieveUserSubscription();
    },
    watch: {
        subscriptionType (value) {
            // PROCESSING
            getAPI.post(`transactions/stripe`, {
                user: sessionStorage.user,
                plan: this.id,
                price: (value==="monthly") ? this.monthly_price_id : this.yearly_price_id
            })
                .then(response => {
                    if(response.status === 200 || response.status === 303) {
                        let data = response.data

                        console.log(data)

                        // PROCESSING
                        if (Array.isArray(data)) {
                            // TODO: JEDIEL -> LABEL A CORRRIGER; STATUS CODE CUSTOM REQUIS
                            if (data.includes('You are available subscription')) {
                                this.$emit('alert', 'Vous avez déjà activé cette souscription.');
                                //this.displayError('Vous avez déjà activé cette souscription.', 'alert-no');
                            }
                        }
                        else {
                            this.loadRemoteLink('https://google.com');
                            // window.location.reload();
                        }
                    }
                    else {
                        this.$emit('alert', 'Vous ne pouvez activé cette souscription.');
                        //this.displayError('Oups ! something went wrong', 'alert-no');
                    }
                    setTimeout(() => {
                        document.getElementById(this.id).style.display='none';
                    }, 2000);
                })
                .catch(error => {
                    if (error.response) {
                        this.loadRemoteLink(error.response.data.url);

                        this.$emit('alert', error.response.data.plan);
                        //this.displayError(error.response.data.message, 'alert-no');
                    }
                    else if (error.request) {

                        this.$emit('alert', 'The request was made but no response was received. Please check your network.');
                        //this.displayError('The request was made but no response was received. Please check your network.', 'alert-no');
                    }
                    else {
                        this.$emit('alert', 'Oups ! something went wrong.');
                        this.displayError('Oups ! something went wrong.', 'alert-no');
                    }
                    setTimeout(() => {
                        document.getElementById(this.id).style.display='none';
                    }, 2000);
                })
        },
    }
}
</script>

<style scoped>
.eGRLiv {
    font-weight: 600;
    font-size: 4.2rem;
    margin: 0px 1rem 0px 0px;
    line-height: 6rem;
    position: relative;
}
.tcmDt {
    font-size: 1.6rem;
    font-weight: 400;
    /*align-self: flex-end;
    transform: translateY(1.2rem);*/
}
.bFilqo {
    display: flex;
    margin-bottom: 1.5rem;
    align-items: center;
}
.selected-plan .course-box{
    background-image: radial-gradient(circle, #02bbc3 0%, #0bbbc4 48%, #0bbbc4 85%, #0b9da5 98%) !important;
    background: var(--primary-color) !important;
    box-shadow: none;
}
.plan_ul_feature li{
    padding: .6em 0;
}
.plan_feature{
    background-color: #cef1f3;
    border-radius: 0.5em;
    height: 1.7em;
    width: 1.7em;
    min-width: 1.7em;
    margin-right: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
}
.plan_feature_inner{
    background: var(--primary-color);
    border-radius: 0.5em;
    height: 1.1em;
    width: 1.1em;
    min-width: 0.9em;
    justify-content: center;
    display: flex;
    align-items: center;
}
.cfa-subscription-btn{
    position: relative;
    z-index: 1;
    display: inline-block;
    padding: 1.1rem 1.5rem 1.2rem;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    user-select: none;
    border: 0;
    border-radius: 0.375rem;
    transition: box-shadow .2s,outline .2s ease;
    -webkit-appearance: none !important;
    appearance: none !important;
    background-color: var(--primary-color);
    color: #fff;
    font-size: 15px;
}
.cfa-subscription-btn2{
    position: relative;
    z-index: 1;
    display: inline-block;
    padding: 1.1rem 1.5rem 1.2rem;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    user-select: none;
    border-radius: 0.375rem;
    border: 2px solid var(--primary-color);
    transition: box-shadow .2s,outline .2s ease;
    -webkit-appearance: none !important;
    appearance: none !important;
    font-size: 15px;
}
.selected-label{
    text-align: center;
    color: #fff;
    font-weight: 400;
    position: absolute;
    bottom: 6px;
    right: 0;
    left: 0;
}

.grise {
  opacity: 0.5;
  cursor: not-allowed;
}
.selected-plan{
    background-image: radial-gradient(circle, #02bbc3 0%, #0bbbc4 48%, #0bbbc4 85%, #0b9da5 98%) !important;
    background: var(--primary-color) !important;
    border-radius: 7px;
    position: relative;
}
.prefetch_overlay{
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.16);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100000000000;
}
.plan_tag{
    padding: 0.5em;
    background: var(--primary-light-color-2);
    font-weight: 600 !important;
    border-radius: 4px;
}
.card {
    margin-bottom: 0 !important;
}
.accordion__item__grid{
    /*margin: 1em 1em 1em 0em;*/
    margin: .5em;
    border: 1px solid #e1e0e0;
}
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
button {
    border: none;
    background: none;
    outline: none;
}
.faq {
    width: 100%;
    /*padding: 0 10px;*/
}
.faq-wrapper {
    /*max-width: 825px;*/
}
.faq__title {
    text-align: center;
    margin-bottom: 25px;
}
.faq__nav {
    display: flex;
    justify-content: space-between;
    border: 2px solid var(--border-color);
    border-radius: 5px;
}
.faq__nav-item {
    height: 50px;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 2px solid var(--border-color);
    cursor: pointer;
    font-weight: 600;
    transition: all 0.3s;
    text-align: center;
    user-select: none;
    color: var(--font-color);
    font-weight: 400;
}
.faq__nav-item_active {
    color: var(--active-color);
    background-color: var(--primary-color);
}
.faq__nav-item:hover {
    /*color: var(--active-color);*/
}
.faq__nav-item:last-child {
    border-right: none;
}
.faq__accordion {
    min-height: 250px;
}
.entreprise-block{
    min-height: 15.5em;
}
.time-block{
    min-height: 13em;
}
.license-block{
    min-height: 30.3em;
}
.cfa-page{

}
.cfa-block{
    background-color: #fff;
    border-radius: 0.5em;
    padding: .5em 1em;
    box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
    -webkit-box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
    -moz-box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);

    /* border: 1px solid transparent;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05); */
}
.ticket-list tr {
    border-color: transparent !important;
}
.codeStyle{
    padding: 0.5em;
    background: #f5eded;
    text-decoration: none;
    color: var(--primary-color);
    font-weight: 400;
    display: inline-block;
    cursor: copy;
}
.gFolder-container{
    background-color: #cef1f3;
    border-radius: 0.5em;
    height: 4.3em;
    width: 4.6em;
    margin-right: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
}
.gBlock{
    align-items: center;
    justify-content: start;
    border-radius: 0.6em;
    padding-left: 2em;
    padding-right: 2em;
    height: 8em !important;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    background-image: url('https://laravel.pixelstrap.com/cuba/build/assets/widget-bg-a2f1cbe6.png');
    background-size: cover;
}
.gBlock2{
    align-items: center;
    justify-content: start;
    border-radius: 0.6em;
    padding-left: 2em;
    padding-right: 2em;
    height: 18.5em !important;
    box-shadow: none;
    background-image: none !important;
    background-color: var(--primary-light-color-2) !important;
}
.gBlock div:last-of-type p{
    margin: 0;
}
.gBlock div:last-of-type p:first-of-type{
    font-weight: 400;
    font-size: 1.4em;
}

.welcome-card {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    color: #fff;
}
.card {
    margin-bottom: 20px;
    border: none;
    transition: all .3s ease;
    letter-spacing: .5px;
    border-radius: 15px;
    box-shadow: 0 9px 20px #2e235e12;
}
.o-hidden {
    overflow: hidden;
}
.card .card-body {
    padding: 30px;
    background-color: transparent;
}
.welcome-card .welcome-img {
    height: 100%;
    position: absolute;
    right: 0;
    bottom: -5px;
}

@media (max-width: 1700px) {
    .welcome-card .welcome-img {
        bottom: -5px;
    }
}
@media (max-width: 1780px) {
    .welcome-card .welcome-img {
        height: 100%;
        bottom: -5px;
    }
}
.course-box {
    overflow: hidden;
}
.square-group {
    position: absolute;
    bottom: 0;
    right: 0;
    transform: scaleX(-1);
}
ul {
    padding-left: 0;
    list-style-type: none;
    margin-bottom: 0;
}
.square-group .warning {
    background: #c8fcff;
}
.square-group .square-1 {
    width: 12px;
    height: 18px;
}
.square-group .primary {
    background: var(--primary-color);
}
.square-group .warning1 {
    background: #FFD497;
}
.square-group .danger {
    background: #f54132;
}
.square-group .light {
    background: #E6E8EF;
}
.square-group .warning {
    background: #c8fcff;
}
.square-group .success {
    background: #54BA4A;
}
.square-group .square-6, .square-group .square-7 {
    position: absolute;
    width: 4px;
    height: 4px;
}
.square-group .square-6, .square-group .square-7 {
    position: absolute;
    width: 4px;
    height: 4px;
}
.square-group .success {
    background: #54BA4A;
}
.course-widget {
    display: flex;
    gap: 8px;
}
.course-widget .course-icon {
    position: relative;
    width: 54px;
    height: 54px;
    clip-path: polygon(40% 7.67949%,43.1596% 6.20615%,46.52704% 5.30384%,50% 5%,53.47296% 5.30384%,56.8404% 6.20615%,60% 7.67949%,81.65064% 20.17949%,84.50639% 22.17911%,86.97152% 24.64425%,88.97114% 27.5%,90.44449% 30.6596%,91.34679% 34.02704%,91.65064% 37.5%,91.65064% 62.5%,91.34679% 65.97296%,90.44449% 69.3404%,88.97114% 72.5%,86.97152% 75.35575%,84.50639% 77.82089%,81.65064% 79.82051%,60% 92.32051%,56.8404% 93.79385%,53.47296% 94.69616%,50% 95%,46.52704% 94.69616%,43.1596% 93.79385%,40% 92.32051%,18.34936% 79.82051%,15.49361% 77.82089%,13.02848% 75.35575%,11.02886% 72.5%,9.55551% 69.3404%,8.65321% 65.97296%,8.34936% 62.5%,8.34936% 37.5%,8.65321% 34.02704%,9.55551% 30.6596%,11.02886% 27.5%,13.02848% 24.64425%,15.49361% 22.17911%,18.34936% 20.17949%);
    background: linear-gradient(310.31deg,#FF3364 14.71%,#FF7F9E 100%);
    display: flex;
    align-items: center;
    justify-content: center;
}
.f-light {
    color: #52526c;
    opacity: .8;
}
.no-wrap{
    white-space: nowrap;
}
.course-widget .btn-light {
    background: #f2f3f7 !important;
    border: none;
}
.course-widget a {
    display: flex;
    align-items: center;
    padding: 8px 12px;
    position: relative;
    margin-top: 5px;
    top: 8px;
}
.card {
    margin-bottom: 20px;
    border: none;
    transition: all .3s ease;
    letter-spacing: .5px;
    border-radius: 7px;
    box-shadow: 0 9px 20px #2e235e12;

    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
}
.card .card-header.card-no-border {
    border-bottom: none!important;
}
.get-card .card-header {
    padding-left: 20px;
    padding-right: 20px;
}
.card .card-header {
    background-color: #fff;
    padding: 30px;
    border-bottom: 1px solid #ecf3fa;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
}
.card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}
.mb-1 {
    margin-bottom: 0.25rem!important;
}
.card .card-body {
    padding: 30px;
    background-color: transparent;
}
.btn-outline-white {
    border: 1px solid #fff;
    color: #fff;
}
.pentagone_icon{
    position: absolute;
    font-size: 28px;
    color: #fff;
}
.course-box{
    transition: .3s all ease-in;
}
.course-box:hover{
    background: #c9fbff;
}
.patch-pf-dot-4{
    padding: 0 0.6em !important;
}
td,th{
    border: 1px solid #eae8e8;
    padding: 0.5em 0.8em;
}
.no-border-right{
    border-right: 1px solid transparent !important;
 }
.cfa-subscription-btn2{
    border-color: #c9fbff !important;
    color: #c9fbff !important;
}
</style>