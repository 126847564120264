<template>
    <div class="page-auth">
        <div class="activation-page">
            <div class="hidden bg-spect lg:flex flex-col items-center justify-evenly">
                <img class="" src="../../assets/auth/images/spec-brand-white.svg"
                        alt="cyberforceacademy logo"  >
                <img class="" src="../../assets/auth/images/icon-auth-banner.svg"
                alt="cyberforceacademy logo" >

                <p class="text-white text-2xl text-center">Placez l’humain au coeur de votre Stratégie
                    de <span class="text-accent">Cyberdéfense</span></p>
                
            </div>
            <div class="content-form flex items-center justify-center py-4 bg-back">
                <div class="mx-auto grid w-3/4 gap-6 bg-white py-8 px-3 rounded-15 ">
                    
                    <div class="grid gap-2 text-center">
                        <h1 class="text-4xl font-[500] text-spect">
                            Activation de compte
                        </h1>
                        <p class="text-balance">
                            Bonjour {{ username }}.
                            Définissez votre mot de passe pour vous connecter à votre compte. Veuillez choisir un mot de passe complexe !
                        </p>
                    </div>
                    
                    <form @submit.prevent="handleSubmit" class="space-y-6">
                        
                        <div>
                            <label class="block font-medium text-gray-700 mb-1">Mot de passe</label>
                            <div class="relative">
                                <input
                                :type="toggle.password ? 'text' : 'password'"
                                v-model="formData.password"
                                class="w-full px-4 py-2 input input-bordered ui-input"
                                placeholder="mot de passe"
                                :class="{ 'ui-input--error': errors.password }"
                                />
                                <span class="absolute right-2 top-3 cursor-pointer" @click="toggle.password = false" v-if="toggle.password" ><i class="fa fa-eye-slash"></i></span>
                                <span class="absolute right-2 top-3 cursor-pointer" @click="toggle.password = true" v-if="!toggle.password" ><i class="fa fa-eye"></i></span>
                            </div>
                            <p v-if="errors.password" class="text-red-500 text-sm mt-1">{{ errors.password }}</p>
                        </div>
                        <div>
                            <label class="block font-medium text-gray-700 mb-1">Confirmez mot de passe</label>
                            
                            <div class="relative">
                                <input
                                :type="toggle.confirmPassword ? 'text' : 'password'"
                                v-model="formData.confirmPassword"
                                class="w-full px-4 py-2 input input-bordered ui-input"
                                placeholder="mot de passe"
                                :class="{ 'ui-input--error': errors.confirmPassword }"
                                />
                                <span class="absolute right-2 top-3 cursor-pointer" @click="toggle.confirmPassword = false" v-if="toggle.confirmPassword" ><i class="fa fa-eye-slash"></i></span>
                                <span class="absolute right-2 top-3 cursor-pointer" @click="toggle.confirmPassword = true" v-if="!toggle.confirmPassword" ><i class="fa fa-eye"></i></span>
                            </div>
                            <p v-if="errors.confirmPassword" class="text-red-500 text-sm mt-1">{{ errors.confirmPassword }}</p>
                        </div>
                        
                        

                        <!-- Submit Button -->
                        <button
                            type="submit"
                            class="w-full bg-spect text-white py-3 rounded-lg hover:bg-opacity-90 transition-colors flex items-center justify-center gap-2 btn-disable " :disabled="isInvalid"
                        >
                            <span class="loading loading-spinner" v-if="isSubmit" ></span>
                            <span>
                                Terminer mon inscription
                            </span>
                        </button>

                        

                        <!-- Login Link -->
                        <div class="text-center text-sm">
                            <span class="text-gray-600">Vous avez un compte? </span>
                            <a href="#" class="text-accent">Connectez-vous ici</a>
                        </div>
                    </form>
                </div>
            </div>

        </div>
        <div class="toast toast-top toast-center my-toast" v-if="isAlert">
            <div class="alert alert-info toast-ctn--info">
                <span>{{ alertMessage }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    name: 'ActivationView',

    data () {
        return {
            toggle: {
                password: false,
                confirmPassword: false,
            },
            formData: {
                password: '',
                confirmPassword: '',
            },
            errors: {
                password: null,
                confirmPassword: null,
            },
            isSubmit: false,
            isAlert: false,
            alertMessage: "New message",
            isInvalid: false
        }
    },
    computed : {
        username () {
            if (this.$route.query.username) {
                return this.decodeBase64String(this.$route.query.username)
            }
            return "John"
        }
    },
    methods: {
        decodeBase64String(encodedString) {
            const decodedString = decodeURIComponent(atob(encodedString).split('').map(function(c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));

            return (decodedString !== 'None') ? decodedString : '';
        },
        async handleSubmit () {
            if (this.validatePassword()) {
                console.log("continue")
                this.isSubmit = true
                await axios.post(`${process.env.VUE_APP_BASE_URL}accounts/activate/${this.$route.query.uid}/${this.$route.query.token}/`,
                {
                    password: this.formData.password,
                    password2: this.formData.confirmPassword
                })
                .then(response => {
                    console.log(response)
                    if(response.status == 200) {
                        this.$router.push({
                            name: "Login",
                        });
                        this.$emit('activated')
                        //this.displayError("Super ! votre compte a été activé avec succès.", 'alert-yes')
                        this.alertMessage = "Super ! votre compte a été activé avec succès." 
                        this.isAlert = true;
                        setTimeout (()=> {
                            this.isAlert = false
                        }, 6000)
                
                    }
                    else {
                        //this.displayError('Oups ! something went wrong.', 'alert-no')
                        //this.waitingAPIResponse = false
                        this.alertMessage = "Une erreur s'est produite. Veuillez rechargez la page" 
                        this.isAlert = true;
                        setTimeout (()=> {
                            this.isAlert = false
                        }, 6000)
                        this.isSubmit = false
                    }
                })
                .catch(error => {
                    console.log(error)
                    localStorage.removeItem('token')
                    this.isSubmit = false
                    if (error.response) {
                        // this.displayError(error.response.data.message, 'alert-no', 10000)
                        //this.displayError('Oups ! Définissez un mot de passe d\'au moins 12 caractères contenant un chiffre et un caractère spécial.', 'alert-no')
                        this.errors.password = " Définissez un mot de passe d'au moins 8 caractères contenant un chiffre et un caractère spécial."
                    }
                    else if (error.request) {
                        //this.displayError('The request was made but no response was received. Please check your network.', 'alert-no', 8000)
                        this.alertMessage = "Une erreur s'est produite. Veuillez rechargez la page" 
                        this.isAlert = true;
                        setTimeout (()=> {
                            this.isAlert = false
                        }, 6000)
                    }
                    else {
                        //this.displayError('Oups ! something went wrong.', 'alert-no', 5000)
                    }
                    this.isSubmit = false
                })
            }
        },
        validatePassword() {
            // Add validation logic for step 2
            this.errors.password = null
            this.errors.confirmPassword = null
            let isValid = true

            if (this.formData.password.length < 8) {
                this.errors.password = 'Mot de passe de 8 caractere mininum et contenant un  caractere special'
                isValid = false
            }
            if (this.formData.password != this.formData.confirmPassword) {
                this.errors.confirmPassword = 'Les mots de passe ne correspondent pas.'
                isValid = false
            }
            return isValid
        },
    },
    mounted () {
        if(!this.$route.query.uid || !this.$route.query.token)  {
            //this.displayError('Oups ! URL invalide')
            this.isInvalid = true
            this.alertMessage = "URL invalide" 
            this.isAlert = true;
            setTimeout (()=> {
                this.isAlert = false
            }, 3000)
        }
    }
}
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/tailwinds.scss";
    
</style>