<template>
    <div class="page-auth">
        <div class="register-page">
            <div class="hidden bg-spect lg:flex flex-col items-center justify-evenly">
                <img class="" src="../../assets/auth/images/spec-brand-white.svg"
                        alt="cyberforceacademy logo"  >
                <img class="" src="../../assets/auth/images/icon-auth-banner.svg"
                alt="cyberforceacademy logo" >

                <p class="text-white text-2xl text-center w-3/4 xl:w-full ">Placez l’humain au coeur de votre Stratégie
                    de <span class="text-accent">Cyberdéfense</span></p>
                
            </div>
            
            <div class="content-form flex items-center justify-center py-4 bg-back">
                <div class="mx-auto grid w-3/4 gap-6 bg-white py-8 px-3 rounded-15 " v-if="!isForgot">
                    
                    <div class="grid gap-2 text-center">
                        <h1 class="text-4xl font-[500] text-spect">
                            Mot de passe oublie
                        </h1>
                        <p class="text-balance">
                            Entrez votre email
                        </p>
                    </div>
                    <form  @submit.prevent="handleSubmit" class="grid gap-3 px-4">
                        
                        <!-- Code -->
                        <div>
                            <label class="block font-medium text-gray-700 mb-1">Email</label>
                            <input
                            type="text"
                            v-model="formData.email"
                            class="w-full px-4 py-2 input input-bordered ui-input"
                            :class="{ 'ui-input--error': errors.email }"
                            placeholder="Entrez votre email"
                            />
                            <p v-if="errors.email" class="text-red-500 text-sm mt-1">{{ errors.email }}</p>

                        </div>


                        <!-- Submit Button -->
                        <button
                            type="submit"
                            class="w-full flex items-center justify-center gap-3 bg-spect text-white py-3 rounded-6 hover:bg-opacity-90 transition-colors" :disabled="isInvalid || isSubmit"
                        >
                            <span class="loading loading-spinner" v-if="isSubmit" ></span>
                            <span>
                                Renitialiser mon mot de passe
                            </span>
                        </button>
                        <!-- Login Link -->
                        <div class="text-center text-sm">
                            <span class="text-gray-600">Vous avez un compte? </span>
                            <a href="/" class="text-accent">Connectez-vous ici</a>
                        </div>
                    </form>
                    
                </div>
                <div class="mx-auto grid w-3/4 gap-6 bg-white py-8 px-3 rounded-15 " v-else>
                    
                    <div class="flex flex-col justify-center items-center gap-3 ">
                        <p class="text-3xl w-full text-center">
                            Mot de passe renitialiser
                        </p>
                        <!-- <video width="256" height="256" preload="none" style="background: transparent url('https://cdn-icons-png.flaticon.com/512/8717/8717946.png') 50% 50% / fit no-repeat;" autoplay="autoplay" loop="true" muted="muted" playsinline="" class="" >
                            <source src="https://cdn-icons-mp4.flaticon.com/512/8717/8717946.mp4" type="video/mp4">
                        </video> -->
                        <label class="w-full text-center">Vérifiez votre boîte de réception. Un e-mail de réinitialisation de mot de passe vous a été envoyé.</label>
                        <!-- <div class="text-center">
                            <span class="text-gray-600">J'ai pas reçu le mail&nbsp; </span>
                            <a  class="text-accent cursor-pointer" @click="resentActivationMail" >Renvoyer le mail d'activation</a>
                        </div>
                        <div class="text-center text-sm">
                            <span class="text-gray-600">Vous avez un compte? </span>
                            <a href="/" class="text-accent">Connectez-vous ici</a>
                        </div> -->
                    </div>
                    
                    
                </div>
            </div>
        </div>
        <div class="toast toast-end my-toast" v-if="isAlert">
            <div class="alert alert-info toast-ctn--info">
                <span>{{ alertMessage }}</span>
            </div>
        </div>
    </div>
</template>

<script>

//import countryCode from '../../components/shared/input/countryCode.vue';
import axios from "axios";

export default {
    name: "NewForgotPasswordView",
    components: {
        //countryCode
    },
    setup() {
        
    },
    data() {
        return {
            dialCode:'',
            isCodeStep: false,
            activityOptions: [],
            formData: {
                email: '',
                organizationName: '',
                organizationId: '',
                organizationDescription: '',
                organizationLogo: '',
                businessType: '',
                country: '',
                service: ''
            },
            errors: {
                email: null,
                lastName: null,
                firstName: null,
                phone: null,
                acceptTerms: null,
                organizationName: null,
                businessType: null,
                code: null
            },
            isSubmit: false,
            isForgot: false,
            isAlert: false,
            alertMessage: "New message",
            isInvalid: false,
            staticService: [],
            service_id: "",
            serviceoptions:[]
        }
    },
    methods: {
        
        
        async handleSubmit () {
            // Handle form submission
            if (this.validateStep2()) {
                //let phoneNumber = this.formData.phone
                this.isSubmit = true
                //console.log(this.formData, phoneNumber)
                
                await axios.post(`${process.env.VUE_APP_BASE_URL}accounts/forgot-password`,
                    {
                        email: this.formData.email,
                        
                    }
                )
                .then(res => {
                    console.log(res)
                    if(res.status == 200) {
                        this.$emit('emailSent')
                        this.isForgot = true
                        //localStorage.setItem('guest_email', this.formData.email);
                        this.emitAlert("Veuillez consulter votre boite de réception email.", 5000)
                    }
                })
                .catch(err => {
                    console.log(err)
                    if (err.response && !err.response.data.success) {
                        this.emitAlert("Une erreur s'est produite", 5000)
                        
                    }
                    this.isSubmit = false
                })
                
            }
        },
        
        validateStep2() {
            // Add validation logic for step 2
            
            this.errors = {
                email: null,
                lastName: null,
                firstName: null,
                phone: null,
                acceptTerms: null,
                organizationName: null,
                businessType: null
            }
            let isValid = true
            if (!this.formData.email || this.formData.email.trim() == "" ) {
                this.errors.email = 'Le champ email est obligatoire.'
                isValid = false
            } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.formData.email)) {
                this.errors.email = 'L\'adresse email n\'est pas valide.'
                isValid = false
            }
            
            return isValid
        },
        
        isNumeric(phoneNumber) {
            return /^[0-9]+$/.test(phoneNumber);
        },
        
        emitAlert(text, duration) {
            this.alertMessage = text 
            this.isAlert = true;
            setTimeout (()=> {
                this.isAlert = false
            }, duration)
        }
    },
    mounted () {
        //this.getActivity()
    },
    watch: {
        
    }

}
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/tailwinds.scss";
    
</style>